<ngb-pagination
    class="float-left"
    [collectionSize]="totalResults"
    [(page)]="pageNumber"
    [pageSize]="pageSize"
    [maxSize]="maxPages | async"
    (pageChange)="doPaged()"
    rotate="true"
    boundaryLinks="true"
    [disabled]="disabled"
></ngb-pagination>
<div ngbDropdown class="d-inline ml-2">
    <button
        type="button"
        class="btn btn-outline-primary"
        id="pageSize"
        ngbDropdownToggle
        [disabled]="disabled"
    >
        {{ pageSize }}
    </button>
    <div ngbDropdownMenu aria-labelledby="pageSize">
        <button
            *ngFor="let ps of pageSizes"
            class="dropdown-item"
            (click)="setPageSize(ps)"
            type="button"
        >
            {{ ps }}
        </button>
    </div>
</div>
<small class="d-inline"
    >&nbsp; {{ "List.TotalResults" | translate }} : {{ totalResults }}
</small>

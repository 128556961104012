import { Injectable } from "@angular/core";
import {
  BaseAttachmentDto,
  FullModelMasterDto,
  generateCheckDigit,
  ListResultDto,
  LookupListEx,
  MachineCategoryDto,
  MachineMasterDto,
  MachineMasterWithCustomerDto,
  SearchDto,
} from "@shared/models";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import moment from "moment";
import { MachineCategories } from "../components";
import { DataService, Progress, ServiceConfig } from "./data.service";
import { LookupListService } from "./lookup-list.service";

@Injectable({
  providedIn: "root",
})
export class MachineService extends DataService {
  constructor(protected config: ServiceConfig, private lookups: LookupListService) {
    super(config);
  }

  queryLoanMachines(
    term: string,
    // warehouseId: string,
    query: string
  ): Observable<MachineMasterDto[]> {
    return this.http.get<MachineMasterDto[]>(
      `machines/loans/${term}?query=${query || ""}` // ${warehouseId || ""}
    );
  }

  queryMachines(query: SearchDto): Observable<ListResultDto<MachineMasterDto>> {
    let url = `machines/search?pageNumber=${query.pageNumber}&pageSize=${
      query.pageSize
    }&orderBy=${query.orderBy[0] || ""}`;

    // eslint-disable-next-line guard-for-in
    for (const key in query.filters) {
      const value = query.filters[key];
      url = url + `&${key}=${value}`;
    }

    return this.http.get<ListResultDto<MachineMasterDto>>(url);
  }

  getMachineWithCustomer(
    machineId: string
  ): Observable<MachineMasterWithCustomerDto> {
    return this.http.get<MachineMasterWithCustomerDto>(
      `machines/${this.safeEncode(machineId)}/withcustomer`
    );
  }

  getMachine(machineId: string, pathExtras?: string): Observable<MachineMasterDto> {
    return this.http.get<MachineMasterDto>(
      `machines/${this.safeEncode(machineId)}${pathExtras || ''}`
    );
  }

  getFullModel(modelId: string): Observable<FullModelMasterDto> {
    return this.http.get<FullModelMasterDto>(
      `machines/model/${modelId}/full`
    );
  }

  newMachine(machine: MachineMasterDto): Observable<MachineMasterDto> {
    return this.http.post<MachineMasterDto>("machines", machine);
  }

  updateMachine(machine: MachineMasterDto): Observable<MachineMasterDto> {
    return this.http.put<MachineMasterDto>(
      `machines/${this.safeEncode(machine.machineId)}`,
      machine
    );
  }

  uploadAttachment(machineId: string, file: File, description: string, progress: Progress, _generateCheckDigit = false, extras?: any, typeId?: string):
  Observable<BaseAttachmentDto> {
    const formData = new FormData();
    formData.append(description, file, file.name);
    formData.append('lastModified', moment(file.lastModified).format()); // moment's default format is ISO 8601
    const url = `machines/${machineId}${_generateCheckDigit ? '/' + generateCheckDigit(machineId) : ''}/attachment${typeId && `/${typeId}` || ''}`;
    return this.http.post<BaseAttachmentDto>(
      url,
      formData,
      {
        reportProgress: true,
        observe: "events"
      }
    )
    .pipe(this.uploading(progress));
  }

  downloadLink(attachment: BaseAttachmentDto, parentId?: string): string {
    if (parentId) {
      parentId += "/";
    }
    return `machines/${parentId}attachment/${attachment.attachmentId}`;
  }

  downloadAttachment(parentId: string, attachmentId: string): Observable<Blob> {
    return this.http
      .get(`machines/${parentId}/attachment/${attachmentId}`, { responseType: "blob" });
  }

  deleteAttachment(parentId: string, attachmentId: string): Observable<any> {
    return this.http.delete(`machines/${parentId}/attachment/${attachmentId}`);
  }


  /**
   * General Categories fetcher - implement this in MachineCategories/SkillCategories...etc.
   * @todo to be implemented as noted above
   */
  getCategories(webCats: string[]): Observable<MachineCategories[]> {
    const catsObs = this.lookups.lookupListEx<MachineCategoryDto>("CodeMachineCategory");

    return catsObs.pipe(map(cats => {
      // GET ALL ACTIVE CATEGORIES
      const mainCategories = cats.values.filter(c => c.active);
      let lastCat: MachineCategoryDto = null;
      let idx = mainCategories.length - 1;
      // ITERATE BACKWARDS OVER LIST
      while (idx) {
        const cat = mainCategories[idx];
        if (!cat.extras) { // add extras (code)
          cat.extras = [];
        }
        if (!lastCat || lastCat.order !== cat.order || lastCat.description !== cat.description) {
          lastCat = cat;
          lastCat.extras = [lastCat.code];
        } else {
          lastCat.extras.push(cat.code);
          mainCategories.splice(idx, 1);
        }
        idx--;
      }// dont really see the point of this...seem really specific

      const result: MachineCategories[] = [];
      const catNamePrefix = 'Machine.Category';
      for (let i = 0; i < webCats.length; i++) {
        const id = i + 1;
        const cat = webCats.find(c => c.includes("" + id)) || "";
        const name = `${catNamePrefix}${id}`;
        const items = mainCategories.filter(v => v.order === id && v.active);// get current 'order' items
        result.push({
          name,
          // filter, wtf?
          filters: items.filter(ii => !!ii.filter).map(f => f.filter).filter((value, index, self) => self.indexOf(value) === index),
          items: new LookupListEx<MachineCategoryDto>(items),
          visible: !!cat,
        });
      }
      return result;
    }));
  }
}

<mat-toolbar color="accent" class="d-print-none">

    <div class="nav-bar-left">

        <!-- HOME BUTTON -->
        <ng-container *ngIf="useSidebarMenu; else useHomeButtonOnly">
            <button
                *ngIf="hasMenu"
                type="button"
                mat-icon-button
                (click)="toggleSidebar()"
                ><i class="fas fa-bars fa-lg" title="Menu"></i>
            </button>
        </ng-container>
        <ng-template #useHomeButtonOnly>
            <button
                *ngIf="(brandInactive | async) === false"
                type="button"
                mat-icon-button
                [routerLink]="tenant"
            >
                <i class="fas fa-home fa-lg" title="{{ 'System.Home' | translate }}"></i>
        </button>
        </ng-template>
    </div>

    <!-- LOGO -->
    <div class="main-logo" style="position: relative">
        <button
            type="button"
            mat-raised-button
            color="basic"
            class="px-md-5 py-1 px-1 logo-button"
            [routerLink]="(brandInactive | async) === false ? '/' : '/login'"
        >
            <img
                [src]="companyLogo"
                class="logo logo-img"
                [alt]="currentCompany?.name"
                [title]="companyName"
                data-cy="nav-bar-logo"
        /></button>
    </div>

    <div class="nav-bar-right">

        <!-- ACTIONS -->
        <div id="nav-actions-container">
            <!-- content will be placed dynamically via Cdk Portal -->
        </div>

        <button
            *ngIf="hasFollowupsMenu"
            type="button"
            aria-label="Followups"
            mat-icon-button
            color="basic"
            (click)="sidenav.toggle()"
        >
            <i
                class="fas fa-calendar-check fa-lg"
                title="{{ 'System.Followups' | translate }}"
                [matBadgeHidden]="!followupCount"
                [matBadge]="followupCount"
                matBadgePosition="after"
                matBadgeColor="warn"
                matBadgeOverlap="false"
            ></i>
        </button>
        <button
            *ngIf="showUserMenu"
            [matMenuTriggerFor]="userMenu"
            type="button"
            aria-label="User Menu"
            mat-icon-button
            color="basic"
        >
            <i class="fas fa-user fa-lg" title="{{ 'JobSummary.User' | translate }}"></i>
        </button>
        <mat-menu #userMenu="matMenu" class="py-0">
            <mat-action-list class="user" *ngIf="username | async as uName">
                <button
                    *ngIf="tenant"
                    mat-list-item
                    [routerLink]="[tenant, 'admin', 'options']"
                >
                    <i
                        matListIcon
                        [ngClass]="['fa-fw text-primary', 'fas fa-cog']"
                    ></i>
                    &nbsp;{{ 'User.Options'| translate }}
                </button>
                <button mat-list-item type="button" (click)="logout()">
                    <i
                        matListIcon
                        [ngClass]="['fa-fw text-primary', 'fas fa-sign-out-alt']"
                    ></i>
                    &nbsp;{{ 'User.SignOut' | translate }} {{ uName }}
                </button>
                <button *ngIf="previousUser()" mat-list-item type="button" (click)="loginAsPreviousUser()">
                    <i
                        matListIcon
                        [ngClass]="['fa-fw text-primary', 'fas fa-user']"
                    ></i>
                    &nbsp;{{ 'User.LoginAsPrevious' | translate }} {{ previousUser() }}
                </button>
            </mat-action-list>
            <mat-action-list class="user" *ngIf="(username | async) === ''">
                <button mat-list-item type="button" (click)="login()">
                    <i
                        matListIcon
                        [ngClass]="['fa-fw text-primary', 'fas fa-sign-in-alt']"
                    ></i>
                    &nbsp;{{ 'User.SignIn' | translate }}
                </button>
            </mat-action-list>
        </mat-menu>
    </div>

</mat-toolbar>

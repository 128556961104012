<div class="modal-header">
    <h4 class="modal-title">{{ modalTitle }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <ng-container *ngIf="!sheetFile;else fileDetails">
        <label class="btn btn-primary btn-file d-block btn-lg">
            <i class="fas fa-file-excel"></i> {{ 'Attachment.SelectFile' | translate }}
            <input type="file" class="d-none" (change)="selectFile($event)" data-testid="capture-file-any" noBootstrap/>
        </label>
        <ng-container *ngIf="fileMessage">
            <div class="alert alert-warning mt-2" role="alert">
                {{ fileMessage }}
            </div>
        </ng-container>
    </ng-container>
    <ng-template #fileDetails>
        <div class="row">
            <div class="col-6">
                <div class="row">
                    <abi-form-field label="File Name" cols="col-12">
                        <input class="form-control-plaintext" [ngModel]="sheetFile.name" disabled/>
                    </abi-form-field>
                    <abi-form-field label="Sheet Name" cols="col-12">
                        <select class="form-control" [ngModel]="sheetName" (ngModelChange)="setSheet($event)">
                            <option *ngFor="let sheet of sheetNames" [value]="sheet">{{ sheet }}</option>
                        </select>
                    </abi-form-field>
                </div>
            </div>
            <div class="col-6">
                <div class="row">
                    <abi-form-field label="Starting Row" cols="col-12">
                        <input class="form-control-plaintext" [ngModel]="selectedRow" disabled/>
                    </abi-form-field>
                    <abi-form-field label="Ending Row" cols="col-12">
                        <input class="form-control-plaintext" [ngModel]="endingRow" disabled/>
                    </abi-form-field>
                </div>
            </div>
        </div>
        <ng-container *ngIf="sheetMessage">
            <div class="alert alert-warning">{{ sheetMessage }}</div>
        </ng-container>

        <div class="table-responsive">
            <table
                #table
                abi-table="ImportPreview"
                [rows]="rowJson"
                [columns]="columns"
                class="table-hover table-striped table-sm"
                [canFilter]="true"
                [colsObs]="colsObs"
                [hiddenColumns]="true"
                (rowClickHandler)="rowClickHandler($event)"
                [trClass]="getTrClass()"
                [skipTranslate]="true"
            >
            <ng-template [td]="dateColumns" let-value="value" let-row="row">
                <span class="text-nowrap">{{ value | date }} </span>
            </ng-template>
            </table>
          </div>
    </ng-template>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" (click)="cancel()"><i class="fas fa-times fa-fw"></i> {{ 'System.Cancel' | translate }}</button>
    <button cdkFocusInitial
        type="button" class="btn btn-outline-success"
        (click)="accept()"
        [disabled]="!canSubmit()">
        <i class="fas fa-check fa-fw"></i> Submit</button>
</div>

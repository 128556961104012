export * from "./surveys";
export * from "./common";

export * from "./CompanyInfoDto";
export * from "./AddressDto";
export * from "./ContactDto";
export * from "./CustomerMasterDto";
export * from "./CustomerAddressDto";
export * from "./CustomerContactDto";
export * from "./JobDayEnum";
export * from "./JobDto";
export * from "./JobErrorDto";
export * from "./SearchDto";
export * from "./JobJournalDto";
export * from "./JobSummaryDto";
export * from "./JobSummaryUserDto";
export * from "./JobLineDto";
export * from "./JobSearchDto";
export * from "./LookupObjectDto";
export * from "./DealerBranchDto";
export * from "./MachineMasterDto";
export * from "./MetricDto";
export * from "./ModelMasterDto";
export * from "./PostalCodeDto";
export * from "./ProductSettingDto";
export * from "./StockMasterDto";
export * from "./StockWarehouseDto";
export * from "./ResourceMasterDto";
export * from "./ResourceVisitDto";
export * from "./UserCompanyDto";
export * from "./UserMetricDto";
export * from "./UserMetricSummaryDto";
export * from "./OrderDto";
export * from "./OrderLineDto";
export * from "./OrderLineReturnDto";

export * from "./JobVisitDto";
export * from "./PickLineDto";
export * from "./PickListDto";

export * from "./DeliveryNoteDto";
export * from "./DeliveryLineDto";
export * from "./DeliveryNoteCustomerDto";
export * from "./PickListSearchDto";

// Stick new stuff at the bottom - stupid webpack / typescript...
export * from "./ResourceLeaveDto";
export * from "./BaseCalendarDto";
export * from "./StockSummaryDto";
export * from "./StockTransactionDto";

export * from "./DocumentLogDto";
export * from "./ErrorLogDto";

export * from "./ResourceScheduleDto";
export * from "./ScheduleJob";
export * from "./MachineCategoryDto";

export * from "./keyed.collection";
export * from "./lookups";
export * from "./MachineListResultDto";

export * from "./BaseAttachmentDto";

export * from "./CustomerVisitDto";
export * from "./CustomerCallCycleDto";
export * from "./JobValidationDto";
export * from "./JobVisitDto";

export * from "./JobStatusDto";
export * from "./JobTypeDto";
export * from "./PublicCustomerDto";
export * from "./TipOfTheDayDto";
export * from "./ZoneDto";
export * from "./UpdateJobDto";
export * from "./LookupAddressDto";

export * from "./OrderLineAttachmentDto";
export * from "./OrderStatusDto";

export * from "./StockPriceResultDto";
export * from "./WebLayoutDto";
export * from "./ResourcePostalCodeRatingDto";
export * from "./ResourceSkillRatingDto";
export * from "./DealerBranchAddressDto";

export * from "./HistoryOrderLineDto";
export * from "./WarehouseMasterDto";

export * from "./LoginDto";
export * from "./ExternalLoginDto";

export * from "./CustomerBankDto";
export * from "./contracts/ContractDto";
export * from "./contracts/ContractMachineDto";
export * from "./contracts/ContractLineDto";
export * from "./contracts/ContractBillingDto";
export * from "./contracts/CodeBankDto";
export * from "./contracts/ContractPaymentDto";


export * from "./jobs/JobHoldTypeDto";
export * from "./jobs/JobHoldDto";

export * from "./orders/OrderLineReturnApprovalDto";

export * from "./contracts/PaymentSummaryDto";
export * from "./MachineJobDto";


export * from "./report/ReportDto";
export * from "./report/GroupDto";

export * from "./jobs/InvoiceDto";
export * from "./jobs/InvoiceLineDto";
export * from "./jobs/JobMachineDto";
export * from "./jobs/MessageTemplateDto";

// WAREHOUSE MANAGEMENT SYSTEM
export * from './wms/BinMasterDto';

// @todo: add full comment and example - place into relevant file (string utilities?)
export function trim(value: string) {
  return value ? value.replace("\t", " ").trim() : value;
}

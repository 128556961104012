import { Component, Input, OnChanges, SimpleChanges, } from "@angular/core";
import { FormArray } from "@angular/forms";
import { LookupListService } from "@modules/common/services/lookup-list.service";
import { ProductSettingService } from "@modules/common/services/product-setting.service";
import { codeOnly, LookupListEx, MachineCategoryDto } from "@shared/models";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

export interface MachineCategories {
  name: string;
  filters: string[];
  items: LookupListEx<MachineCategoryDto>;
  visible: boolean;
}

/**
 * Generate Category Selections from Settings derived Counts and values
 * Uses Settings: MachineCategoryCount, MachineCategoriesWeb and Lookup CodeMachineCategory
 * @todo combine the above settings in a more structured and clear way to generate this UI
 */
@Component({
  selector: "abi-machine-categories",
  templateUrl: "./machine-categories.component.html",
  styleUrls: ["./machine-categories.component.scss"],
})
export class MachineCategoriesComponent implements OnChanges {
  @Input() categoryControls: FormArray;
  @Input() allCategories = false;
  @Input() horizontal = true;
  @Input() useCardDisplay = true;
  @Input() lookupDisplay: 'all' | 'name' | 'code' = 'all';
  @Input() entityType: 'Machine' | 'Stock' = 'Machine';

  private categoryCount: number;

  categories: MachineCategories[];
  hiddenCategories: boolean[] = [];

  constructor(private productSettings: ProductSettingService, private lookups: LookupListService) {
    // Defined Number of Machine Categories - CODE SMELL: duplicate value derived from a deifferent source
    this.categoryCount = productSettings.numericValue(`${this.entityType}CategoryCount`) || 5;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getCategories(
      this.allCategories ? Array.from(new Array(this.categoryCount), (val, index) => "" + (index + 1)) :
        // Defined Number of Machine Categories - CODE SMELL: duplicate definition of value
        this.productSettings.stringValue(`${this.entityType}CategoriesWeb`).split(",")
    )
    .subscribe(c => this.categories = c);
    this.setupCategoryFilters();
  }


  categoryFilters = [];// memoised filters - better performance
  setupCategoryFilters() {
    Array(this.categoryCount).fill(0).forEach((v, i) => {
      this.categoryFilters[i] = (item) => this.categoryFilter(i, item);
    });
  }

  categoryFilter(level: number, item: MachineCategoryDto): boolean {
    if (this.categories[level].filters && item.parentId) {
      const pFilter = this.categoryControls.value[(+item.parentId) - 1];
      if (!pFilter) {
        return true;
      } else {
        return this.categories[level].filters.includes(codeOnly(pFilter))
        ? (item.filter === codeOnly(pFilter))
        : !item.filter;
      }
    } else {
      return !item.filter;
    }
  }

  private getCategories(webCats: string[]): Observable<MachineCategories[]> {
    if(this.categoryCount !== webCats.length){
      throw new Error("Category Count and WebCategories Length don't match.")
    }
    // Number and Details of Machine Categories - CODE SMELL: duplicate defined value
    const catsObs = this.lookups.lookupListEx<MachineCategoryDto>(`Code${this.entityType}Category`);

    return catsObs.pipe(map(cats => {
      const mainCategories = cats.values.filter(c => c.active);
      let lastCat: MachineCategoryDto = null;
      let idx = mainCategories.length - 1;
      while (idx) {
        const cat = mainCategories[idx];
        if (!cat.extras) {
          cat.extras = [];
        }
        if (!lastCat || lastCat.order !== cat.order || lastCat.description !== cat.description) {
          lastCat = cat;
          lastCat.extras = [lastCat.code];
        } else {
          lastCat.extras.push(cat.code);
          mainCategories.splice(idx, 1);
        }
        idx--;
      }

      const result: MachineCategories[] = [];

      for (let i = 0; i < webCats.length; i++) {
        const cat = webCats.find(c => c.includes("" + (i + 1))) || "";
        const id = i + 1;
        const name = `${this.entityType}.Category${id}`;
        const items = mainCategories.filter(v => v.order === id && v.active);
        result.push({
          name,
          filters: items.filter(ii => !!ii.filter).map(f => f.filter).filter((value, index, self) => self.indexOf(value) === index),
          items: new LookupListEx<MachineCategoryDto>(items),
          visible: !!cat,
        });

        this.hiddenCategories.push(!cat);
        if (this.categoryControls.parent?.enabled && this.categoryControls.at(i)) {
          if (!cat) {
            // this.categoryControls.at(i).disable();
          } else {
            // this.categoryControls.at(i).enable();
          }
        }
      }
      return result;
    }));
  }
}

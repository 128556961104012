import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { DataService, ServiceConfig } from "@services";
import {
  FullMenuDto,
  JobSummaryUserDto,
  ListResultDto,
  LoginDto,
  Mode,
  SearchDto,
  UserDto,
  UserMetricDto,
  UserMetricSummaryDto,
  WebMenuDto,
} from "@shared/models";
import { combineLatest, Observable, ReplaySubject } from "rxjs";
import { auditTime, filter, map, take } from "rxjs/operators";
import { FormControl, ValidatorFn } from "@angular/forms";
import { LoginStatus } from "../app.store";

@Injectable({
  providedIn: "root",
})
export class UserService extends DataService {
  private _currentUser: JobSummaryUserDto;
  private _userIdToSet: string;

  usersLoaded: ReplaySubject<JobSummaryUserDto[]> = new ReplaySubject(1);

  get currentUser(): JobSummaryUserDto {
    return this._currentUser;
  }

  set currentUser(value: JobSummaryUserDto) {
    this._currentUser = value;
    if (value) {
      this.session().setItem("UserId", value.userId);
    }
  }

  get currentUserId() {
    return this._currentUser ? this._currentUser.userId : "";
  }

  users: JobSummaryUserDto[];

  constructor(config: ServiceConfig, private translate: TranslateService) {
    super(config);
    // this.appQuery.$loginStatus.pipe(tap(stat => console.warn(`UserService: Login Status: ${stat}`)))
    const validTenant = this.appQuery.$tenant2.pipe(auditTime(10));
    const isLoggedIn =  this.appQuery.$loginStatus;

    combineLatest([validTenant, isLoggedIn])
      .pipe(
        filter(([t, l]) => !!t && l === LoginStatus.True),
        this.notDisposed(),
        // tap(([tenant, loggedin]) => console.log(`tenant:${tenant} - loggedin:${loggedin}`))
      )
      .subscribe(() => {
          this.reloadTenant();
      });
  }

  public reloadTenant() {
    const usersL = this.getSummaryUsers()
    .pipe(take(1)).toPromise()
    .then((lst) => {
      this.users = lst;
      const id =
        this._userIdToSet ||
        this.session().getItem("userId") ||
        this.appQuery.username;
      this.setCurrentUser(!id ? "_" : id);
      return lst;
    });

    const calls = Promise.all([
      this.getSettings().toPromise(),
      this.getMenu().toPromise(),
      this.getFullMenu().toPromise(), // add new menu here
    ]);

    // this.store.setLoading(true);
    const settingL = calls.then(([set, menu, fullmenu]) => {
      if (set) {
        const pKeys = Object.keys(set).filter((k) => k.startsWith("P~"));
        const claims = Object.assign(
          {},
          ...pKeys.map((s) => ({ [s.replace("P~", "")]: +set[s] }))
        ) as { [id: string]: number };
        this.appStore.update({
          wideScreen: true, // set["WideScreen"] === "true",
          homescreenLinks: false, // set["HomescreenLinks"] !== "false",
          defaultResource: set["DefaultResource"],
          warehouse: set["Warehouse"],
          warehouse2: set["Warehouse2"],
          skipResource: set["SkipResource"] === "true",
          primaryRole: set["PrimaryRoleID"],
          pinSidenav: set["PinSidenav"] as any,
          warehouses: set["Warehouses"] || [],
          language: set["Language"] || "en",
          claims,
        });
      }
      this.appStore.update({ menu, fullmenu });
      const newLang = (set["Language"] as string) || this.appQuery.language || "en";
      this.translate
        .reloadLang(newLang)
        .toPromise()
        .then(() => {
          this.translate.use(newLang);
        });
    });

    Promise.all([usersL, settingL]).then((_) => this.usersLoaded.next(_[0]));
  }

  canSeeOwners() {
    return this.users && this.users.some((u) => u.mode === Mode.Owner);
  }

  /**
   * Old Menu API
   */
  getMenu(): Observable<WebMenuDto[]> {
    return this.http.get<WebMenuDto[]>("values/menu");
  }

  /**
   * New Full Menu API
   */
  getFullMenu(): Observable<FullMenuDto[]> {
    return this.http.get<FullMenuDto[]>("values/fullmenu");
  }

  getSummaryMetrics(): Observable<UserMetricSummaryDto[]> {
    return this.http.get<UserMetricSummaryDto[]>("users/metrics");
  }

  getSummaryUsers() {
    return this.http.get<JobSummaryUserDto[]>("jobs/summaryusers");
  }

  // ALL users
  getUsers(): Observable<string[]> {
    return this.http.get<string[]>("users/list");
  }

  // Search all users
  searchUsers(query: SearchDto): Observable<string[]> {
    return this.http.get<string[]>("users/list", { params: this.searchQueryToParams(query)});
  }

  // Admin Search
  searchLogins(params: SearchDto): Observable<ListResultDto<UserDto>> {
    return this.http.get<ListResultDto<UserDto>>('users/login/search', { params: this.searchQueryToParams(params) });
  }

  // Admin List by Company
  getLogins(companyId?: string): Observable<UserDto[]> {
    let params = new HttpParams();
    if (companyId) params = params.set('companyid', companyId);
    return this.http.get<UserDto[]>("users/login/list", {
      params
    });
  }

  getLogin(userId: string): Observable<UserDto> {
    return this.http.get<UserDto>(`users/login/${userId}`);
  }

  updateLoginUser(user: UserDto): Observable<UserDto> {
    return this.http.put<UserDto>(`users/login/${user.userId}`, user);
  }

  createLoginUser(user: UserDto): Observable<UserDto> {
    return this.http.post<UserDto>(`users/login`, user);
  }

  loginFactory(): UserDto {
    return {
      userId: "",
      description: "",
      password: "",
      sid: "",
      domain: "",
      username: "",
      emailAddress: "",
      companies: [],
      roles: [],
      resources: [],
      warehouses: [],
      branches: [],
    };
  }

  setCurrentUser(userId: string) {
    if (this.users) {
      this._currentUser =
        !userId || userId === "_" || userId === "*"
          ? this.users[0]
          : this.users.find((u) => u.userId === userId);

      if (this.currentUser) {
        this.session().setItem("userId", this._currentUser.userId);
      } else {
        this._currentUser = this.users[0];
      }
      return this._currentUser;
    } else {
      this._userIdToSet = userId;
    }
  }

  getMetrics(userId: string): Observable<UserMetricDto[]> {
    return this.http.get<UserMetricDto[]>(`users/${userId}/metrics`);
  }

  getSettings(): Observable<{ [settingId: string]: any }> {
    return this.http.get<{ [settingId: string]: any }>("users/settings");
  }

  requestResetPassword(email: string): Observable<string> {
    return this.http
      .get(`users/reset?email=${email}`)
      .pipe(map((s: any) => s.email));// CODE SMELL: email resonse is not used anywhere
  }

  sendWelcomeMailer(username: string): Observable<any> {
    return this.http.get(`users/newuser/${username}`);
  }

  resetPassword(
    code: string,
    userId: string,
    password: string
  ): Observable<string> {
    return this.http
      .post("users/reset", { code, password, userId })
      .pipe(map((s: any) => s.email));
  }

  getProfile(): Observable<LoginDto> {
    return this.http.get<LoginDto>("users/profile");
  }
  /*
  userSetting(setting: string): string {
    if (!this.query.hasEntity(setting)) {
      this.store.add({setting, value: "true"});
    }
    return this.query.getEntity(setting).value;
  }

  selectSetting(setting: string): Observable<string> {
    if (!this.query.hasEntity(setting)) {
      this.store.add({setting, value: "true"});
    }
    return this.query.selectEntity(setting, s => s.value);
  }
*/
  updateLogin(
    current: string,
    password: string,
    email: string
  ): Observable<any> {
    return this.http.put("users/updatePassword", { current, password, email });
  }

  updateSetting(setting: string, value: string): Observable<string> {
    return this.http
      .put(`users/settings/${setting}`, { value })
      .pipe(map((s) => setting));
  }

  updateExternal(
    provider: string,
    email: string,
    externalId: string
  ): Observable<any> {
    return this.http.put(`users/external/${provider}`, { email, externalId });
  }

  deleteExternal(provider: string): Observable<any> {
    return this.http.delete(`users/external/${provider}`);
  }

  updateUserCompany(userId: string, companyId: string) {
    return this.http.put<UserDto>(`users/login/${userId}/company/${companyId}`, {});
  }

  deleteUserCompany(userId: string, companyId: string) {
    return this.http.delete(`users/login/${userId}/company/${companyId}`);
  }

  public passwordsMatch(): ValidatorFn {
    return (control: FormControl) => {
      if (!control.parent) {
        return null;
      }
      const a = control.parent.get("newPassword").value;
      const b = control.parent.get("confirmNewPassword").value;
      if (!!a && !!b && a !== b) {
        return { match: true };
      }
      return null;
    };
  }
}

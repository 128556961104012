import { Injectable } from '@angular/core';
import { DealerBranchDto, ListResultDto, newAddress, newCustomerContact, SearchDto } from "@modules/models";
import { DealerMasterDto } from "@shared/models/DealerMasterDto";
import { Observable, of } from "rxjs";
import { DataService, ServiceConfig } from "..";

export function newBranch(code = '', description = '', active = true): DealerBranchDto {
  return {
    dealerId: "*",
    branchId: "",
    baseCalendarId: "",
    code,
    description,
    order: 0,
    active,
    // TODO: confirm these props (see that API side models are consistent)
    address: newAddress(),
    contact: newCustomerContact()
  };
}

export function newDealer(code = '', description = '', active = true): DealerMasterDto {
  return {
    code,
    description,
    branches: [],
    active,
    priceList: null,
    note: "",
    categoryId: "",
    order: 0
  };
}


@Injectable({
  providedIn: 'root'
})
export class DealerService extends DataService {
  constructor(config: ServiceConfig) { super(config); }

  // CUSTOM API STUFF HERE
  // // TODO: add/apply BRANCHES APIS HERE
  // getBranch(branchId: string): Observable<DealerBranchDto> {
  //   return of(newBranch());
  // }
  // values/item/dealerbranch {POST}
  createBranch(model: DealerBranchDto): Observable<DealerBranchDto> {
    return this.http.post<DealerBranchDto>(`values/item/dealerbranch/${model.dealerId}`, model);
  }
  // values/item/dealerbranch/{dealerId} {PUT}
  updateBranch(model: DealerBranchDto): Observable<DealerBranchDto> {
    return this.http.put<DealerBranchDto>(`values/item/dealerbranch/${model.code}`, model);
  }
  // values/item/dealerbranch/{branchId} {GET}
  getBranch(modelId: string): Observable<DealerBranchDto> {
    return this.http.get<DealerBranchDto>(`values/item/dealerbranch/${modelId}`);
  }

  queryBranches(query: string, dontUseAccessPermissions = false): Observable<DealerBranchDto[]> {
    return this.http.get<DealerBranchDto[]>(
      `values/list/DealerBranch${dontUseAccessPermissions ? "/all" : ''}?query=${query || ""}`
    );
  }

  // GENERIC ADMIN
  // values/search/dealermaster {GET}
  query(query: SearchDto): Observable<ListResultDto<DealerMasterDto>> {
    return this.http.get<ListResultDto<DealerMasterDto>>('values/search/dealermaster', { params: this.searchQueryToParams(query) });
  }
  // values/item/dealermaster {POST}
  create(model: DealerMasterDto): Observable<DealerMasterDto> {
    return this.http.post<DealerMasterDto>('values/item/dealermaster', model);
  }
  // values/item/dealermaster/{dealerId} {PUT}
  update(model: DealerMasterDto): Observable<DealerMasterDto> {
    return this.http.put<DealerMasterDto>(`values/item/dealermaster/${model.code}`, model);
  }
  // values/item/dealermaster/{dealerId} {GET}
  get(modelId: string): Observable<DealerMasterDto> {
    return this.http.get<DealerMasterDto>(`values/item/dealermaster/${modelId}`);
  }
}

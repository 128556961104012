import { HttpClient } from "@angular/common/http";
import { Directive } from "@angular/core";
import { LookupObjectDto } from "@shared/models";
import { from, Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { BaseObservableTypeaheadHelper } from "./base-typeahead-helper";
import { TypeaheadDirective } from "./typeahead.directive";

@Directive({
  selector: "input[abiTypeahead][abiIcon]"
})
export class IconTypeaheadDirective extends BaseObservableTypeaheadHelper<LookupObjectDto> {
  static icons: LookupObjectDto[] = null;

  constructor(typeAhead: TypeaheadDirective, private http: HttpClient) {
    super(typeAhead, []);
    this.preLoad = true;
    this.inputDisplay = "name";
  }

  protected getId(item: LookupObjectDto): string {
    return item.code;
  }

  protected getName(item: LookupObjectDto): string {
    return item.description;
  }

  private stripfa(name: string): string {
    return name.replace("fas ", "").replace("far ", "").replace("fa-", "").replace("-", " ");
  }

  protected filteredList(term: string): Observable<LookupObjectDto[]> {
    const search = this.regExp(this.stripfa(term));

    const qry = IconTypeaheadDirective.icons ? from([IconTypeaheadDirective.icons]) :
      this.http.get<string[]>("/assets/fontawesome.json").pipe(map(r => r.map(i => ({
        code: i,
        description:  this.stripfa(i),
        order: 0,
        active: true,
      }))), tap(icons => IconTypeaheadDirective.icons = icons));

    return qry.pipe(map(icons => icons.filter(item => search.test(item.description)
      && (!this.filter || this.filter(item, term))))); // .slice(0, 50)
  }
}


import { Directive, Input } from "@angular/core";
import { BinMasterDto } from "@shared/models";
import { Observable, of } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { WarehouseService } from "../services/warehouse.service";
import { TypeaheadDirective } from "./typeahead.directive";
import { BaseObservableTypeaheadHelper } from "./base-typeahead-helper";

@Directive({
  selector: "input[abiTypeahead][abiWarehouseBin]"
})
export class WarehouseBinTypeaheadDirective extends BaseObservableTypeaheadHelper<BinMasterDto> {
  @Input() abiWarehouseBin: string = '';
  @Input() codeOnly = false;

  constructor(typeAhead: TypeaheadDirective, private warehouseService: WarehouseService) {
    super(typeAhead, ["abiWarehouseBin"]);
    this.loading = false;
  }

  protected getId(item: BinMasterDto): string {
    return item.code;
  }
  protected getName(item: BinMasterDto): string {
    return item.description;
  }

  protected getFull(item: BinMasterDto): string {
    return item.code + " - " + item.description;
  }

  protected filteredList(term: string): Observable<BinMasterDto[]> {
    return this.warehouseService.getWarehouseBins(this.abiWarehouseBin)
    // @todo: move this to the superclass
    .pipe(
      mergeMap(orders => {
        return this.preFilter && of(orders.filter(o => this.preFilter(o))) || of(orders);
      })
    );
  }
}

import {
  HttpClient,
  HttpClientModule,
  HttpParams,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import {
  APP_INITIALIZER,
  ErrorHandler,
  Inject,
  Injector,
  NgModule,
  PLATFORM_ID,
} from "@angular/core";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
} from "@angular/material/core";
import {
  BrowserModule,
  BrowserTransferStateModule,
} from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import {
  ServiceWorkerModule,
  SwRegistrationOptions,
} from "@angular/service-worker";
import {
  NgbDateAdapter,
  NgbDateNativeAdapter,
  NgbDatepickerConfig,
} from "@ng-bootstrap/ng-bootstrap";
import {
  MissingTranslationHandler,
  TranslateLoader,
  TranslateModule,
} from "@ngx-translate/core";
import * as Sentry from "@sentry/angular-ivy";
import { NgxMaskModule } from "ngx-mask";
import {
  CalendarDateFormatter,
  CalendarModule,
  CalendarMomentDateFormatter,
  DateAdapter as CalendarDateAdapter,
  MOMENT,
} from "angular-calendar";
import { adapterFactory } from "angular-calendar/date-adapters/moment";
import moment from "moment";
import { ConnectionServiceModule } from "@modules/connection-service/connection-service.module";
import { AppDateAdapter } from "@app/shared/DateAdapter";
import { APP_DATE_FORMATS } from "@app/shared/APP_DATE_FORMATS";
import { HttpLoaderFactory } from "@app/shared/TranslateHttpLoader";
import { getEnv } from "../util";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { FooterComponent } from "./core/footer/footer.component";
import { ForgotPasswordComponent } from "./core/forgot-password/forgot-password.component";
import { HomeComponent } from "./core/home/home.component";
import { LoginComponent } from "./core/login/login.component";
import { AppStore } from "./modules/common/app.store";
import { SharedModule } from "./modules/common/common.module";
import {
  AuthenticationService,
  BrowserStorage,
  KeyGetter,
  ListDataService,
  MyMissingTranslationHandler,
  StorageService,
} from "./modules/common/services";
import {
  RefreshTokenInterceptor,
  UrlInterceptor,
} from "./modules/common/url.interceptor";
import { SidebarModule } from "./modules/sidebar/sidebar.module";
import { NavBarComponent } from "./core/nav-bar/nav-bar.component";
import { PlaceHolderComponent } from "./core/placeholder.component";
import { PlatformInfoHeaderComponent } from "./core/platform-info-header/platform-info-header.component";
import { ResetPasswordComponent } from "./core/reset-password/reset-password.component";
import { ResetComponent } from "./core/reset/reset.component";
import { TipOfTheDayComponent } from "./core/tip-of-the-day/tip-of-the-day.component";

const swDomains = ["darkred.app", "wms.kic.co.za"];//"service.kic.co.za",

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    PlaceHolderComponent,
    ResetComponent,
    TipOfTheDayComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    NavBarComponent,
    FooterComponent,
    PlatformInfoHeaderComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: "ability" }),
    RouterModule,
    ServiceWorkerModule.register("/ngsw-worker.js"),
    NgxMaskModule.forRoot(),
    SharedModule,
    HttpClientModule,
    ConnectionServiceModule,
    TranslateModule.forRoot({
      defaultLanguage: "en",
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MyMissingTranslationHandler,
      },
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    SidebarModule,
    CalendarModule.forRoot(
      {
        provide: CalendarDateAdapter,
        useFactory: momentAdapterFactory,
      },
      {
        dateFormatter: {
          provide: CalendarDateFormatter,
          useClass: CalendarMomentDateFormatter,
        },
      }
    ),
  ],
  providers: [
    KeyGetter,
    { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: UrlInterceptor, multi: true },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: SwRegistrationOptions,
      useFactory: () => ({
        enabled: swDomains.some(dom => location.hostname.includes(dom)),
        registrationStrategy: "registerImmediately",
      }),
    },
    {
      provide: MOMENT,
      useValue: moment,
    },
  ],
})
export class BaseAppModule {}

/**
 * @deprecated use A14's injector feature
 */
export let AppInjector: Injector;
@NgModule({
  declarations: [],
  imports: [
    BaseAppModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    BrowserTransferStateModule,
  ],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
    KeyGetter,
    {
      provide: APP_INITIALIZER,
      useFactory: getMapsKey,
      deps: [KeyGetter, HttpClient],
      multi: true,
    },
    { provide: StorageService, useClass: BrowserStorage },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  private urlParsingNode: any;
  private language: string;

  constructor(
    dateconfig: NgbDatepickerConfig,
    @Inject(PLATFORM_ID) private platformId: string,
    appStore: AppStore,
    authService: AuthenticationService,
    private injector: Injector
  ) {
    AppInjector = this.injector;// Assign global AppInjector - useful for getting DI instances when manually instanciating classes
    this.startupParameters();
    if (authService.getRefreshToken()) {
      authService.doLoggedIn();
    }

    appStore.update({
      language: this.language || "",
      serverEnvironment: getEnv(),
    });

    // customize default values of datepickers used by this component tree
    dateconfig.minDate = { year: 1900, month: 1, day: 1 };
    dateconfig.maxDate = { year: 2099, month: 12, day: 31 };
    dateconfig.firstDayOfWeek = 7;

    // days that don't belong to current month are not visible
    /*
        // weekends are disabled
        config.markDisabled = (date: NgbDateStruct) => {
          const d = new Date(date.year, date.month - 1, date.day);
          return d.getDay() === 0 || d.getDay() === 6;
        };*/
  }

  private relativePath(url: any): string {
    if (!this.urlParsingNode) {
      this.urlParsingNode = document.createElement("a");
    }
    this.urlParsingNode.setAttribute("href", url);
    return this.urlParsingNode.pathname.charAt(0) === "/"
      ? this.urlParsingNode.pathname
      : "/" + this.urlParsingNode.pathname;
  }

  private startupParameters() {
    const params = new HttpParams({ fromString: location.search.substring(1) });
    const access = params.get("a");
    const refresh = params.get("r");
    this.language = params.get("lang");
    let tenant = "";
    if (
      localStorage.getItem("rememberMe") === "true" &&
      (localStorage.getItem("refresh_token") ||
        sessionStorage.getItem("refresh_token"))
    ) {
      tenant = localStorage.getItem("tenant");
    }
    if (access && refresh) {
      localStorage.setItem("token", access);
      if (!!localStorage.getItem("rememberMe")) {
        localStorage.setItem("refresh_token", refresh);
      } else {
        sessionStorage.setItem("refresh_token", refresh);
      }

      tenant = "";
    }
    if (this.language) {
      sessionStorage.setItem("language", this.language);
    }
    if (this.language || (access && refresh))
      history.pushState(null, "", location.pathname);

    const url = this.relativePath(document.URL);
    if (tenant && (!url || url === "/")) {
      history.pushState({}, "Ability", tenant);
    }
  }
}

export function getMapsKey(appInitService: KeyGetter) {
  return (): Promise<any> => {
    return appInitService.getKey();
  };
}

export function momentAdapterFactory() {
  return adapterFactory(moment);
}

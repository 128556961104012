<ng-container
    *ngIf="categories && categoryControls?.length"
    [formGroup]="categoryControls">
    <ng-container *ngIf="useCardDisplay; else categoriesOnly">
        <div class="model-info card" >
            <div class="card-body">
                <ng-content select="h5[role=model-header]"></ng-content>
                <div class="model-info-fields clearfix">
                    <div class="model-categories row">
                        <ng-template ngFor let-item [ngForOf]="categories" let-i="index">
                            <abi-form-field
                                *ngIf="!hiddenCategories[i]"
                                [label]="item.name"
                                cols="col-12"
                                [class]="'category'+(i+1)"
                                [horizontal]="horizontal"
                            >
                                <div class="input-group">
                                    <input
                                        abiTypeahead
                                        [formControlName]="i"
                                        [id]="item.propName"
                                        [abiLookup]="item.items"
                                        [preFilter]="categoryFilters[i]"
                                        [dropDownDisplay]="lookupDisplay"
                                        [inputDisplay]="lookupDisplay"
                                    />
                                </div>
                            </abi-form-field>
                        </ng-template>
                    </div>
                    <ng-content select="div[role=model-info]"></ng-content>
                </div>
            </div>
        </div>
        <div class="model-details card">
            <div class="card-body">
                <h5 class="card-title">Purchase Details</h5>
                <ng-content select="div[role=model-details]"></ng-content>
            </div>
        </div>
    </ng-container>
    <ng-template #categoriesOnly>
        <div class="model-categories row">
            <ng-template ngFor let-item [ngForOf]="categories" let-i="index">
                <abi-form-field
                    *ngIf="!hiddenCategories[i]"
                    [label]="item.name"
                    cols="col-12"
                    [class]="'category'+(i+1)"
                    [horizontal]="horizontal"
                >
                    <div class="input-group">
                        <input
                            abiTypeahead
                            [formControlName]="i"
                            [id]="item.propName"
                            [abiLookup]="item.items"
                            [preFilter]="categoryFilters[i]"
                            [dropDownDisplay]="lookupDisplay"
                            [inputDisplay]="lookupDisplay"
                        />
                    </div>
                </abi-form-field>
            </ng-template>
        </div>
    </ng-template>
</ng-container>

export interface ItemKeys { [index: string]: number} ;

export class KeyedCollection<T> {

  private itemKeys: ItemKeys = {};

  getKeys(): ItemKeys {
    return this.itemKeys;
  }

  constructor(private items: T[], private key: (item: T) => string) {
    this.updateKeys();
  }

  private updateKeys(){
    this.items.forEach((item, index) => this.itemKeys[this.key(item)] = index);
  }

  // Append (optionally update existing item)
  public add(item: T) {
    if (!this.containsKey(this.key(item))) {
      this.items.push(item);
      this.itemKeys[this.key(item)] = this.items.length - 1;
    } else {
      this.update(this.key(item), item);
    }
  }

  public update(key: string, item: T) {
    this.items[this.itemKeys[key]] = item;
  }

  public containsKey(key: string): boolean {
    return (this.itemKeys) ? key in this.itemKeys : false;
  }

  public count(): number {
    return this.items.length;
  }

  public item(key: string): T {
    return this.items[this.itemKeys[key]];
  }

  public get values(): T[] {
    return this.items;
  }

  public clear() {
    this.items = [];
    this.itemKeys = {};
  }

  public remove(key: string) {
    if (this.containsKey(key)){
      this.items.splice(this.itemKeys[key], 1);
      delete this.itemKeys[key];
    }
  }

  public prepend(item: T) {
    this.items.splice(0, 0, item);
    this.updateKeys();// all indices changed
  }
}

import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { enableAkitaProdMode } from "@datorama/akita";
import { environment } from "@env/environment";
import * as Sentry from "@sentry/angular-ivy";
import { BehaviorSubject } from "rxjs";
import { AppModule } from "./app/app.module";
import { ADD_TO_HOMESCREEN } from "./app/modules/common";
import { getEnv, getSentryConfig, ServerEnvironment } from "./util/index";

const addToHome = new BehaviorSubject<any>(null);
const favIconMap = new Map<string, string>([
  ["service.kic.co.za", "assets/tenants/kic/favicon.ico"],
  ["wms.kic.co.za", "assets/tenants/kic/favicon.ico"],
]);
const themeMap = new Map<string, string>([
  // ["service.kic.co.za", "styles-kic.css"],
  // ["wms.kic.co.za", "styles-kic.css"],
  ["/kic", "styles-kic.css"],
  ["/wsal", "styles-kic.css"],
  ["/whirlpool", "styles-whirlpool.css"],
  ["/raleigh", "styles-raleigh.css"],
  ["/genric", "styles-genric.css"],
  ["/trojan", "styles-trojan.css"],
  ["/skelcore", "styles-evo-skelcore.css"],
  ["/ozone", "styles-ozone.css"],
  ["/johnson", "styles-johnson.css"],
  ["/matrix", "styles-matrix.css"],
  ["/horizon", "styles-horizon.css"],
  ["/vision", "styles-vision.css"],
  ["/evom-demo/public/catalog", "styles-evo-product-catalog.css"],
  ["/evom/public/catalog", "styles-evo-product-catalog.css"],
]);
/**
 * Only activate for Prod and Test (and staging when that's available)
 */
function runSentry() {
  const serverEnv = getEnv();
  if (![ServerEnvironment.LOCALHOST.toString()].includes(serverEnv)){
    Sentry.init(getSentryConfig(serverEnv));
  }
}

let originalFavicon: string;
function changeFavicon(): void {
  let faviconLink: HTMLLinkElement;
  const hostname = window.location.hostname;
  const links = document.getElementsByTagName("link");
  // eslint-disable-next-line @typescript-eslint/prefer-for-of
  for (let i = 0; i < links.length; i++) {
    const link = links[i];
    if (link.rel === "icon") {
      if (link.href.includes("favicon.ico")) {
        faviconLink = link;
        originalFavicon = link.href || "";
        break;
      }
    }
  }
  if(faviconLink){
    if(favIconMap.has(hostname)){
      faviconLink.href = favIconMap.get(hostname) || "";
    } else {
      faviconLink.href = originalFavicon;
    }
  }
}

let originalHref: string;
/**
 * Set the Tenant theme before the App module is initialised
 * note that this function is duplicated on nav-bar - we should look at a single place to control this
 */
function changeTheme(): void {
  let themeLink: HTMLLinkElement;
  const documentURL = document.URL;
  if (documentURL.includes("/jx") || documentURL.includes("/public")) {
    const links = document.getElementsByTagName("link");
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < links.length; i++) {
      const link = links[i];
      if (link.rel === "stylesheet") {
        if (link.href.includes("styles-app.")) {
          themeLink = link;
          originalHref = link.href || "";
          break;
        }
      }
    }
  }
  if (themeLink) {
    if (themeMap.has(documentURL))
      themeLink.href = themeMap.get(documentURL) || "";
    else
      themeLink.href = originalHref;
  }
}

function addToHomeScreen(event: any) {
  if (window.location.href.includes("/public/")) {
    console.log("A2HS: Block for public");
    event.preventDefault();
  } else {
    console.log("A2HS: Prompt");
    // event.prompt();
    addToHome.next(event);
  }
  // addToHome.next(event);
  // only listen for 1 iteration
  // window.removeEventListener("beforeinstallprompt", addToHomeScreen);
}
window.addEventListener("beforeinstallprompt", addToHomeScreen);

if (environment.production) {
  enableProdMode();
  enableAkitaProdMode();
}

document.addEventListener("DOMContentLoaded", () => {
  runSentry();
  changeTheme();
  changeFavicon();
  platformBrowserDynamic([
    { provide: ADD_TO_HOMESCREEN, useValue: addToHome }
  ])
    .bootstrapModule(AppModule)
    .catch(err => console.error(err));
});

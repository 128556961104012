import { Component, Input, OnInit } from "@angular/core";
import { RouteInfo } from "@app/core/home/home.component";
import { Disposable } from "@modules/common";
import { AppQuery, AppStore } from "@modules/common/app.store";
import { FollowupsService } from "@modules/common/services";
import { FullMenuDto } from "@modules/models";
import { ReportDtoLayout } from "@modules/reporting/menu/menu.component";
import { Subject, zip } from "rxjs";
import { debounceTime, distinctUntilChanged, filter, take } from "rxjs/operators";
import { iconAnimation, labelAnimation, sidebarAnimation } from "../animations";
import { SidebarService, SideBarState } from "../sidebar.service";

@Component({
  selector: "abi-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
  animations: [sidebarAnimation(), iconAnimation(), labelAnimation()],
})
export class SidebarComponent extends Disposable implements OnInit {
  @Input() isMobile = true;
  sidebarState: string;
  menuTree: RouteInfo[];
  tenant: string;
  reportsLayouts: ReportDtoLayout[] = [];
  hoverTrigger: Subject<any> = new Subject();
  outTrigger: Subject<any> = new Subject();
  outTimer: ReturnType<typeof setTimeout>;
  openTimer: ReturnType<typeof setTimeout>;
  followupCount = 0;

  ignoreHover = false;

  constructor(
    private sidebarService: SidebarService,
    public appQuery: AppQuery,
    public appStore: AppStore,
    private followupService: FollowupsService
  ) {
    super();
  }

  disablePanels = false;
  ngOnInit() {
    // Handle Sidebar state changes
    this.sidebarService.sidebarStateObservable$
    .pipe(this.notDisposed())
    .pipe(distinctUntilChanged())
    .subscribe(
      (newState: string) => {
        this.sidebarState = newState;
        if (newState === SideBarState.CLOSE){
          // ensure set a flag that open cannot be be triggered within a timeout duration
          this.ignoreHover = true;
          setTimeout(() => {
            this.ignoreHover = false;
            this.disablePanels = true;// prevent panels from opening when clicked during 'rails' mode
          }, 600);

        } else {
          setTimeout(() => {
            // Re-Enable the panels after the Touch events have fired...
            this.disablePanels = false;
          }, 300);
        }
      }
    );

    // Re-get the Layouts/Reports when the Tenant Changes
    const validTenant = this.appQuery.$tenant2.pipe(filter((t) => !!t));

    validTenant
    .subscribe((t) => {
      this.tenant = t;
    });

    // New Full Menu
    zip(validTenant, this.appQuery.fullmenu$)
    .pipe(this.notDisposed(), take(1))
    .subscribe(
      ([t, fullmenu]) => {
        this.menuTree = this.buildFullMenutree(fullmenu);
      }
    );

    // can only trigger a hover after about 1sec after the close status changed
    this.hoverTrigger
    .pipe(this.notDisposed(), debounceTime(100))
    .subscribe(() => {

      // after first hover... need to wait a certain amount of time before opening
      // if a 'out' was triggered in that time - cancel it
      if (this.outTimer) {
        clearTimeout(this.outTimer);
        this.outTimer = undefined;
      }

      if(!this.openTimer) {
        if(this.sidebarState === SideBarState.OPEN) {
          // trigger immediately - to prevent closing
          this.sidebarService.open();
        } else {
          this.openTimer = setTimeout(() => {
            this.sidebarService.open();
            this.openTimer = undefined;
          }, 500);
        }
      }
    });

    // AUTO close the sidebar after about 1sec from the mouseout
    this.outTrigger
    .pipe(this.notDisposed(), debounceTime(100))
    .subscribe(o => {

      if (this.outTimer) {
        clearTimeout(this.outTimer);
        this.outTimer = undefined;
      }
      if(this.openTimer) {
        clearTimeout(this.openTimer);
        this.openTimer = undefined;
      }

      this.outTimer = setTimeout(() => {
        this.sidebarService.close();
        this.outTimer = undefined;
      }, 500);
    });

    this.followupService.getBadgeCountObservable().subscribe(count => {
      this.followupCount = count;
    });
  }

  /**
   * Prevent Touch events from interfering with existing Hover events
   */
  touchStart(e) {
    this.ignoreHover = true;
    setTimeout(() => {
      this.ignoreHover = false;
    }, 500);
    if (!this.ignoreHover && this.sidebarState === SideBarState.CLOSE) {
      this.hoverTrigger.next();
    }
    // if touch is triggerd on CLOSE state, this means the menu is PINNED and we must show the menu (for mobile)
    // if we touched a 'link' - then we must not show the menu
    if(this.sidebarState === SideBarState.CLOSE){
      if(e.target.tagName === 'I' && this.elementHasParentWithClass(e.target, 'mat-expansion-panel-header', true, 3)){
        // open menu
        this.sidebarService.open();
      }
    }
  }

  elementHasParentWithClass(element: HTMLElement, className: string, recursive: boolean = false, maxRecursions: number = 5): boolean {
    if(!recursive)
      return element.parentElement.classList.contains(className);
    else if(maxRecursions > 0) {
      if(element.parentElement.classList.contains(className))
        return true;
      else
        return this.elementHasParentWithClass(element.parentElement, className, recursive, maxRecursions-1);
    }
    return false;
  }

  triggerHover(e) {
    if (!this.ignoreHover && this.sidebarState === SideBarState.CLOSE || !this.ignoreHover && this.sidebarState === SideBarState.OPEN) {
      this.hoverTrigger.next();
    }
  }

  // Only Trigger out if the sidebar is open (Desktop)
  triggerOut(e) {
    // if (!this.ignoreHover && this.sidebarState === SideBarState.OPEN) {
    if (!this.ignoreHover) {
      this.outTrigger.next();
    }
  }

  convertFullMenuToRoutes(fullmenu: FullMenuDto[]) {
    return fullmenu
    .map((childItem) =>  new RouteInfo(
        childItem.description,
        childItem.url,
        childItem.icon,
        childItem.parentId,
        childItem.menuId,
        this.convertFullMenuToRoutes(
          childItem.children || [],
        ),
        this.tenant
      )
    );
  }

  buildFullMenutree(fullmenu: FullMenuDto[]): RouteInfo[] {
    return this.convertFullMenuToRoutes(fullmenu);
  }

  toggleRails(){
    this.appStore.update({ hideRails: !this.appQuery.hideRails});
  }
}

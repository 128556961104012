import { Location } from "@angular/common";
import { Component, Input } from "@angular/core";

@Component({
  selector: "abi-back-button",
  template: `<button type="button" class="btn btn-link text-muted text-decoration-none" (click)="back()">
    <i class="fas fa-arrow-left"></i></button>`,
  styleUrls: ['./back-button.component.scss'],
})

export class BackButtonComponent {
  @Input() alternativeBack: () => void;

  constructor(private location: Location) { }

  back() {
    if(this.alternativeBack)
      this.alternativeBack();
    else
      this.location.back();
  }
}

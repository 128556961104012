<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{ title | translate }}</h4>
    <div class="header-actions">
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
        <ng-container *ngIf="showSpinner">
            <!-- {{ displayCallInfo(currentApiCall) }} --><i id="api-busy" class="fas fa-spinner fa-pulse"></i>
        </ng-container>
    </div>

</div>
<div class="modal-body">
    <ng-template [ngTemplateOutlet]="body"></ng-template>
</div>
<div class="modal-footer">
    <ng-container *ngFor="let button of buttons">
        <button type="button" [disabled]="button.disabled?.()" [class]="button.class" (click)="button.action?.(activeModal)">{{ getLabel(button) | translate }}</button>
    </ng-container>
</div>

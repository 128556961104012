import {
  AfterViewInit,
  Component,
  EventEmitter,
  forwardRef,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
} from "@angular/core";
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { codeOnly, ContactDto } from "@shared/models";
import { CustomerContactDto, trim } from "@shared/models";
import { FieldSettings } from "@shared/models/FieldSettings";
import { map } from "rxjs/operators";
import { EMAIL_REGEX, ID_NUMBER_MASK, ID_NUMBER_REGEX, PHONE_MASK, PHONE_REGEX } from "@modules/common/utilities/string.utilities";
import {
  CUSTOM_LAYOUT_ID,
  ProductSettingService,
  WebLayoutService,
} from "../../services";
import { AtLeastOneFieldValidator } from "../../validation";
import { BaseFormDirective } from "../base-form";
import { BaseModal } from "../base-modal";
import { VALIDATION_MESSAGES } from "../form-field/form-field.component";

const validationMessages = {
  names: {
    required: "Contact.AtLeast1Name",
  },
  numbers: {
    required: "Contact.AtLeast1Number",
  },
  mobile: {
    pattern: "Contact.InvalidNumberMobile",
    mask: "Contact.InvalidNumberMask",
  },
  officeHours: {
    pattern: "Contact.InvalidNumber",
  },
  afterHours: {
    pattern: "Contact.InvalidNumber",
  },
  emailAddress: {
    required: "Contact.EmailRequired",
    email: "Contact.InvalidEmail",
    pattern: "Contact.InvalidEmail",
  },
  idNumber: {
    required: "Required",
    pattern: "Contact.InvalidIDNumber",
  }
};

interface Contact {
  contact: ContactDto | CustomerContactDto;
  disabled: boolean;
}

@Component({
  selector: "abi-contact",
  templateUrl: "contact.component.html",
  providers: [
    { provide: VALIDATION_MESSAGES, useValue: validationMessages },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ContactComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ContactComponent),
      multi: true,
    },
  ],
})
export class ContactComponent
  extends BaseModal<ContactDto>
  implements OnInit, ControlValueAccessor, Validator, AfterViewInit, OnChanges
{
  static phoneRegex = PHONE_REGEX;
  static idNumberRegex = ID_NUMBER_REGEX;
  static emailRegex = EMAIL_REGEX;

  @Output() nameUpdated: EventEmitter<string> = new EventEmitter();
  @Output() groupValidationChanged: EventEmitter<boolean> = new EventEmitter();
  @Input() validates = true;
  @Input() type: "Customer" | "Resource" | "Dealer" = "Customer"; // type of Contact
  @Input() context: "Master" | "Registration" | "Job" = "Master"; // where this is used
  @Input() group: FormGroup;
  @Input() emitValidationChangeEvents: boolean = true;
  isInited = false;
  fieldSettings: FieldSettings;
  phoneMask = PHONE_MASK;
  idNumberMask = ID_NUMBER_MASK;
  data: ContactDto | CustomerContactDto | Contact;

  private mobileRegEx: RegExp;
  private contact?: ContactDto | CustomerContactDto;
  private onTouched = () => {};

  constructor(
    layoutService: WebLayoutService,
    private productSettings: ProductSettingService,
    public fb: FormBuilder,
    @Optional() dialogRef?: MatDialogRef<any>,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    data?: ContactDto | CustomerContactDto | Contact
  ) {
    super(layoutService, null, dialogRef);
    this.inModal = dialogRef;
    this.data = data;
  }

  get layoutName(): string {
    return `${this.type}Contact${this.context}`;
  }

  withCountrySelector = false;
  ngOnInit() {
    this.withCountrySelector = this.productSettings.booleanValue('PhoneInputInternational');
    this.initForm();
    this.getFieldSettings().subscribe(() => {
      this.setValidation(this.validates); // requires fieldSettings
      this.isInited = true;
    });
  }

  initForm() {
    if(this.withCountrySelector){
      // Not ZA Specific
      // disable mask
      this.phoneMask = "";
      // disable regex validation
      this.mobileRegEx = null;
    } else {
      // ZA - Country
      const useMask = this.productSettings.numericValue("PhoneMask") === 0;
      this.phoneMask = useMask
        ? this.productSettings.stringValue("PhoneMask") || this.phoneMask
        : "";
      this.mobileRegEx = this.generateMobilePhoneRegex("MobilePhoneCodeZA");
    }

    if(!this.group) this.group = this.createFormGroup(this.fb);
    // this.group = this.createFormGroup(this.fb);
    this.group.valueChanges.pipe(this.notDisposed()).subscribe((c) => {
      if (c.names.firstName || c.names.lastName) {
        this.nameUpdated.emit(
          `${c.names.firstName || ""} ${c.names.lastName || ""}`
        );
      } else {
        this.nameUpdated.emit("New Contact");
      }
    });

    if (this.data) {
      if ("disabled" in this.data) {
        this.contact = this.data.contact;
        if (this.data.disabled) this.group.disable();
      } else this.contact = this.data;
    }

    if (this.inModal) {
      this.dialogRef.updateSize("700px");
      this.setFormData(this.contact);
    }
  }

  getMobileValidators(): any[]{
    return this.withCountrySelector ? [] : (this.mobileRegEx ? [Validators.pattern(this.mobileRegEx)] : []);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.isInited && changes.validates) {
      // console.log( this.type, "validates changed", changes.validates);
      this.setValidation(this.validates);
    }
  }

  setValidation(flag: boolean) {
    // console.log( this.type, "setValidation", flag);
    if (flag) this.addValidation(this.group);
    else this.removeValidation(this.group);
    // this.group.updateValueAndValidity();
    // this.groupValidationChanged.emit(flag);
    // this.validate(this.group);
  }

  writeValue(contact: ContactDto | CustomerContactDto): void {
    this.contact = contact;
    this.setFormData(contact);
  }

  registerOnChange(fn: any): void {
    this.group.valueChanges
      .pipe(
        map((v) => {
          // console.log( this.type, "value changed", v);
          this.getFormData(this.contact);
          return this.contact;
        })
      )
      .subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) this.group.disable();
    else this.group.enable();
  }

  validate(control: AbstractControl): ValidationErrors {
    if (this.group) {
      const errors = BaseFormDirective.allErrors(this.group);
      // console.log( this.type, "validate", errors);
      return this.group.valid ? null : errors;
    }
    return null;
  }

  registerOnValidatorChange?(fn: () => void): void {
    // throw new Error("Method not implemented.");
  }

  setFormData(model: ContactDto | CustomerContactDto) {
    if (!model) return;

    this.group.patchValue({
      title: model.title,
      names: {
        firstName: trim(model.firstName),
        lastName: trim(model.lastName),
        companyName: trim(model.companyName || ""),
        note: trim(model.note || ""),
      },
      instantMessageId: trim(model.instantMessageId),
      emailAddress: trim(model.emailAddress),
      idNumber: trim(model.idNumber),
      numbers: {
        mobile: trim(model.mobile),
        officeHours: trim(model.officeHours),
        afterHours: trim(model.afterHours),
      },
    });
    this.group.markAsPristine();
  }

  getFormData(contact: ContactDto | CustomerContactDto) {
    const value = this.group.value;
    if (!value || !contact) return;
    contact.title = codeOnly(value.title);
    contact.firstName = value.names.firstName;
    contact.lastName = value.names.lastName;
    contact.instantMessageId = value.instantMessageId;
    contact.emailAddress = value.emailAddress;
    contact.idNumber = value.idNumber;
    contact.mobile = value.numbers.mobile;
    contact.officeHours = value.numbers.officeHours;
    contact.afterHours = value.numbers.afterHours;
    // TODO: remove 'companyName' completely and rather use Note going forward (this is extracted to Customer.Company anyways)
    // if ("companyName" in contact) {
      // TODO: could leverage the Web Layout setting to determine this result
    contact.companyName = value.names.companyName;
    // }
    contact.note = value.names.note;
  }

  private generateMobilePhoneRegex(setting: string): RegExp {
    let mobiles = this.productSettings.stringValue(setting);
    if (mobiles) {
      const elems = mobiles.split(",");
      mobiles = elems.map((e) => e.substr(1, 2)).join("|");
      mobiles = "0(" + mobiles + ")|[1-9][0-9]{2}";
    } else {
      mobiles = "[0-9]{3}";
    }
    const reg = `\\(?${mobiles}\\)?[0-9]{3}-?[0-9]{4}`;
    return new RegExp(reg);
  }

  // 'validates' property overrides
  removeValidation(group: FormGroup) {
    group.get("names").clearValidators();
    group.get("names").updateValueAndValidity();
    group
      .get("emailAddress")
      .setValidators([Validators.pattern(ContactComponent.emailRegex)]);
    group.get("emailAddress").updateValueAndValidity({ emitEvent: this.emitValidationChangeEvents });
    group.get("idNumber").clearValidators();
    group.get("idNumber").updateValueAndValidity({ emitEvent: this.emitValidationChangeEvents });
    group.get("numbers").clearValidators();
    group.get("numbers").updateValueAndValidity({ emitEvent: this.emitValidationChangeEvents });
    group.get("numbers").get("mobile").setValidators(this.getValidation("mobile"));
    group.get("numbers").get("mobile").updateValueAndValidity({ emitEvent: this.emitValidationChangeEvents });
    group.updateValueAndValidity({ emitEvent: this.emitValidationChangeEvents });
    // this.validate(group);
  }

  // 'validates' property overrides
  addValidation(group: FormGroup) {
    group.get("names").setValidators(this.getValidation("names"));
    group.get("names").updateValueAndValidity({ emitEvent: this.emitValidationChangeEvents });
    group.get("idNumber").setValidators(this.getValidation("idNumber"));
    group.get("idNumber").updateValueAndValidity({ emitEvent: this.emitValidationChangeEvents });
    group.get("emailAddress").setValidators(this.getValidation("emailAddress"));
    group.get("emailAddress").updateValueAndValidity({ emitEvent: this.emitValidationChangeEvents });
    group.get("numbers").setValidators(this.getValidation("numbers"));
    group.get("numbers").updateValueAndValidity({ emitEvent: this.emitValidationChangeEvents });
    group.get("numbers").get("mobile").setValidators(this.getValidation("mobile"));
    group.get("numbers").get("mobile").updateValueAndValidity({ emitEvent: this.emitValidationChangeEvents });
    group.updateValueAndValidity({ emitEvent: this.emitValidationChangeEvents });
    // this.validate(group);
  }

  optionalEmail(control: AbstractControl): ValidationErrors | null {
    return control.value ? Validators.email(control) : null;
  }

  // optionally use FieldSetting to control validation per field
  getValidation(fieldName: string) {
    if (
      this.fieldSettings &&
      fieldName in this.fieldSettings &&
      this.fieldSettings[fieldName].validates !== undefined
    ) {
      if (this.fieldSettings[fieldName].validates === true) {
        // USE VALIDATION
        switch (fieldName) {
          case "names":
            return AtLeastOneFieldValidator();
          case "emailAddress":
            return [
              Validators.required,
              Validators.pattern(ContactComponent.emailRegex),
            ];
          case "numbers":
            return AtLeastOneFieldValidator();
          case "mobile":
            return this.getMobileValidators();
          case "idNumber":
            return [Validators.pattern(ContactComponent.idNumberRegex)];
          default:
            return null;
        }
      } else {
        // EXPLICITY use NO validations (validates = false)
        switch (fieldName) {
          case "emailAddress":
            return [Validators.pattern(ContactComponent.emailRegex)];
          default:
            return null;
        }
      }
    } else {
      // DEFAULTS
      switch (fieldName) {
        case "names":
          return AtLeastOneFieldValidator();
        case "emailAddress":
          return [Validators.pattern(ContactComponent.emailRegex)];
        case "numbers":
          return AtLeastOneFieldValidator();
        case "mobile":
          return this.getMobileValidators();
        case "idNumber":
          return [Validators.pattern(ContactComponent.idNumberRegex)];
        default:
          return null;
      }
    }
  }

  static createFormGroup(fb: FormBuilder) {
    return fb.group({
      title: "",
      names: fb.group({
        firstName: "",
        lastName: "",
        companyName: "",
        note: "",
      }),
      instantMessageId: "",
      emailAddress: [""],
      idNumber: "",
      numbers: fb.group({
        mobile: "",
        officeHours: "", // ["", Validators.pattern(this.phoneRegex)],
        afterHours: "", // ["", Validators.pattern(this.phoneRegex)]
      }),
    });
  }

  public createFormGroup(fb: FormBuilder) {
    return ContactComponent.createFormGroup(fb);
  }

  public accept(): void {
    this.getFormData(this.contact);
    this.dialogRef.close(this.contact);
  }

  public close(): void {
    this.dialogRef.close(false);
  }

  showField(fieldName: string, defaultVisibility: boolean = true) {
    if (this.fieldSettings?.[fieldName]?.disabled === undefined) return defaultVisibility; // default
    return this.fieldSettings?.[fieldName]?.disabled === false ? true : false;
  }
}

import { DragDropModule } from "@angular/cdk/drag-drop";
import { LayoutModule } from "@angular/cdk/layout";
import { ObserversModule } from "@angular/cdk/observers";
import { OverlayModule } from "@angular/cdk/overlay";
import { PortalModule } from "@angular/cdk/portal";
import { CdkTableModule } from "@angular/cdk/table";
import { TextFieldModule } from "@angular/cdk/text-field";
import { NgModule } from "@angular/core";
import { MatBadgeModule } from "@angular/material/badge";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCardModule } from "@angular/material/card";
import { MatOptionModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatTreeModule } from "@angular/material/tree";

const exportedModules = [
  OverlayModule,
  MatButtonModule,
  MatExpansionModule,
  MatMenuModule,
  MatIconModule,
  MatSidenavModule,
  MatToolbarModule,
  MatInputModule,
  MatTreeModule,
  // MatCardModule,
  // MatSliderModule,
  MatProgressBarModule,
  // MatAutocompleteModule,
  MatFormFieldModule,
  // MatGridListModule,
  MatSnackBarModule,
  MatProgressSpinnerModule,
  MatTooltipModule,
  MatDialogModule,
  MatDividerModule,
  MatDatepickerModule,
  MatListModule,
  MatSlideToggleModule,
  MatButtonToggleModule,
  MatCardModule,
  // MatSlideToggleModule,
  // MatSidenavModule,
  // MatFormField,
  MatSelectModule,
  MatOptionModule,
  // MatOption,
  MatBadgeModule,

  LayoutModule,
  PortalModule,
  DragDropModule,
  CdkTableModule,
  TextFieldModule,
  ObserversModule,
];

@NgModule({
  imports: [...exportedModules],
  exports: [...exportedModules],
  declarations: []
})
export class MaterialModule {}

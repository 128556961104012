import { CustomerAddressDto } from "@shared/models";

export class Coordinates implements google.maps.LatLngLiteral {
  lat: number;
  lng: number;
  constructor(coords: number[] | string[]) {
    this.lat = +coords[0];
    this.lng = +coords[1];
  }

  toString() {
    return `${this.lat.toFixed(4)},${this.lng.toFixed(4)}`;
  }
}

export interface AddressDto {// extends HasCoordinates {
  addressId?: string;
  name: string;
  lines: string[]; // = Array(5).fill("");
  postalCode: string;
  country: string;
  gpsType: string;
  gpsQuality: string;
  gpsCoordinates: number[];
  primary?: boolean;
}

export function asGoogleText(self: AddressDto) {
  let result = "";
  if(!self.lines?.length) return result;
  /*
  if (self.lines[0]) {
    result += self.lines[0] + ", ";
  } */
  if (self.lines[1]) {
    result += self.lines[1] + ", ";
  }
  if (self.lines[2]) {
    result += self.lines[2] + ", ";
  }
  if (self.lines[3]) {
    result += self.lines[3] + ", ";
  }
  if (self.lines[4]) {
    result += self.lines[4] + ", ";
  }
  if (self.postalCode) {
    result += self.postalCode;
  }
  // if (self.lines[4])
  //   result += self.lines[4] + ' ';

  return result;
}

export function mapFromPlace(place: google.maps.places.PlaceResult, self: AddressDto) {
  mapFromAddress(place, self);
  self.name = place.name;
}

export function mapFromAddress(place: google.maps.places.PlaceResult | google.maps.GeocoderResult, self: AddressDto) {
  self.lines = Array(5).fill("");
  let line = 2;
  for (const comp of place.address_components) {
    switch (comp.types[0]) {
      case "street_number":
        self.lines[1] = comp.short_name + " "; // street
        break;
      case "route":
        self.lines[1] += comp.long_name; // street
        break;
      case "sublocality":
      case "sublocality_level_1":
      case "sublocality_level_2":
        self.lines[line++] = comp.long_name;
        break;
      case "locality":
        self.lines[Math.max(line++, 3)] = comp.long_name;
        break;
      case "administrative_area_level_1": // province
        self.lines[4] = comp.long_name;
        break;
      case "postal_code":
        self.postalCode = comp.long_name;
        break;
      case "country":
        self.country = comp.short_name;
        break;
    }
  }
  self.gpsCoordinates = [place.geometry.location.lat(), place.geometry.location.lng()];
  self.gpsType = place.types[0];
}

export function asTextLine(self: AddressDto) {
  let result = "";
  if (self.lines[0]) {
    result += self.lines[0] + " ";
  }
  if (self.lines[1]) {
    result += self.lines[1] + " ";
  }
  if (self.lines[2]) {
    result += self.lines[2] + " ";
  }
  if (self.lines[3]) {
    result += self.lines[3] + " ";
  }
  if (self.postalCode && self.postalCode !== "0000") {
    result += self.postalCode;
  }
  // if (this.line5)
  //   result += this.line5 + ' ';

  return result;
}

/**
 * Generates a pre-formatted text block for address display
 * @param self
 * @returns
 */
export function asTextBlock(self: AddressDto, withName = false) {
  let result = "";

  if(self?.name && withName) {
    result += self.name + "\n";
  }
  // if(!self?.lines?.length) return result;
  if(!self?.lines?.length) return result;
  if (self.lines[0]) {
    result += self.lines[0] + "\n";
  }
  if (self.lines[1]) {
    result += self.lines[1] + "\n";
  }
  if (self.lines[2]) {
    result += self.lines[2] + "\n";
  }
  if (self.lines[3]) {
    result += self.lines[3];
  }
  if (self.postalCode && self.postalCode !== "0000") {
    result += ", " + self.postalCode;
  }
  // if (self.lines[4])
  //   result += this.line5 + ' ';

  return result;
}

export function firstLine(self: AddressDto): string {
  for (const line of self.lines) {
    if (line && line.trim()) {
      return line;
    }
  }
}
export function line34(self: AddressDto): string {
  return self.lines[2] || self.lines[3];
}

export function decodeGpsCoordinates(gpsCoordinates: string): number[] {
  const elems = gpsCoordinates.split(",");
  if (elems.length < 2) {
    return [];
  } else {
    return [+elems[0], +elems[1]];
  }
}

export function hasCoordinates(self: AddressDto) {
  return self.gpsCoordinates && self.gpsCoordinates.length > 0;
}

export function hasLines(self: AddressDto) {
  return self.lines?.some(l => !!l);
}

export function coordinates(self: AddressDto | CustomerAddressDto): google.maps.LatLngLiteral {
  const coords = self.gpsCoordinates;
  return coords && coords.length > 1 ? new Coordinates(coords) : null;
}

export function isAddress(object: any): object is AddressDto {
  const myInterface = object as AddressDto;
  return myInterface && myInterface.lines !== undefined;
}

export function newAddress(): AddressDto {
  return {
    name: "",
    lines: Array(5).fill(""),
    postalCode: "",
    country: "",
    gpsType: "",
    gpsQuality: "",
    gpsCoordinates: [],
  };
}


export function formatAddress(address: AddressDto): string {
  let result = address.name || "";
  if (!result.includes(" ")) {
    result = result + " " + firstLine(address);
  }
  return result;
}

export function GeoPosToGpsCoordinates(geo: GeolocationPosition | number[]): number[] {
  return Array.isArray(geo) ? geo : [geo.coords.latitude, geo.coords.longitude];
}

import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";

export interface Paged {
  pageNumber: number;
  pageSize: number;
}

@Component({
  selector: "abi-pager",
  templateUrl: "./pager.component.html",
  styleUrls: ["./pager.component.scss"],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => PagerComponent),
    multi: true
  }]
})
export class PagerComponent implements OnInit, ControlValueAccessor {
  @Input() totalResults: number;
  @Output() paged: EventEmitter<Paged> = new EventEmitter();
  @Input() pagedO: Subject<Paged>;
  @Input() pageNumber = 1;
  pageSize: number;
  pageSizes = [20, 50, 100];
  maxPages: Observable<number>;

  @Input() disabled = false;

  private _onTouched = () => { };
  private _onChange = (_: any) => { };

  constructor(private responsive: BreakpointObserver) {
    this.pageSize = Math.max(+localStorage.getItem("pageSize"), 20);
    this.maxPages = this.responsive.observe(Breakpoints.Handset).pipe(map(bp => bp.matches ? 1 : 6));
  }

  ngOnInit(): void {
    this.doPaged();
  }

  writeValue(paged: any): void {
    if (paged) {
      this.pageSize = paged.pageSize;
      this.pageNumber = paged.pageNumber || 1;
    }
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  setPageSize(size: number) {
    localStorage.setItem("pageSize", size.toString());
    this.pageSize = size;
    this.pageNumber = 1;
    this.doPaged();
  }

  doPaged() {
    const data = { pageNumber: this.pageNumber || 1, pageSize: this.pageSize };
    this._onChange(data);
    if (this.pagedO) {
      this.pagedO.next(data);
    }
    this.paged.emit(data);
    this._onTouched();
  }
}

import { Component, Inject, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { ADD_TO_HOMESCREEN, Disposable } from "@modules/common";
import { AppQuery } from "@modules/common/app.store";
import {
  FollowupsService,
  ProductSettingService,
  TipOfTheDayService,
  UserService,
  WebLayoutService,
} from "@modules/common/services";
import {
  dummyMetric,
  UserCompanyDto,
  UserMetricSummaryDto,
  WebLayoutDto,
} from "@shared/models";
import { BehaviorSubject, combineLatest, concat, from, Observable } from "rxjs";
import { distinctUntilChanged, filter, map, mergeMap, shareReplay, tap } from "rxjs/operators";
import { TipOfTheDayComponent } from "../tip-of-the-day/tip-of-the-day.component";

export class RouteInfo {
  layouts: WebLayoutDto[];
  get link(): any[] {
    const lnk = this.innerLink.map((l) => (l instanceof Function ? l() : l));
    return !!this.parentId ? ["../../", ...lnk] : lnk;
  }
  get absoluteLink(): any[] {
    const lnk = this.innerLink.map((l) => (l instanceof Function ? l() : l));
    return !!this.tenant ? [this.tenant, ...lnk] : lnk;
  }
  constructor(
    public name: string,
    private innerLink: any[],
    public image: string,
    public parentId?: string,
    public layoutName?: string,
    public children?: RouteInfo[],
    public tenant?: string,
    public description?: string
  ) {}
}

@Component({
  selector: "abi-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent extends Disposable implements OnInit {
  readonly loadingItem = new RouteInfo("...", [], "fas fa-ellipsis-h");
  readonly loadingCompany: UserCompanyDto = {
    id: "",
    name: "...",
    icon: "loading",
    code: ""
  };

  mainRoutesMaster: RouteInfo[] = [];
  metrics: Observable<UserMetricSummaryDto[]>;
  haveTips: Observable<boolean>;
  mainRoutesMaster$: Observable<RouteInfo[]>;
  mainRoutes: Observable<RouteInfo[]>;
  subRoutes: Observable<RouteInfo[]>;
  metricRoutes: Observable<RouteInfo[]>;
  childRoutes: Observable<RouteInfo[]>;
  childName: Observable<RouteInfo>;
  childMode = false;
  followupCount = 0;
  loading = true;
  tenant$: Observable<string>;
  masterCompanies: Observable<UserCompanyDto[]>;
  companies: UserCompanyDto[] = [];
  testCompanies: UserCompanyDto[] = [];
  username: Observable<string>;
  hideCustomerRoles: string[];

  constructor(
    public appQuery: AppQuery,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private productSettingService: ProductSettingService,
    private dialog: MatDialog,
    private tipService: TipOfTheDayService,
    private layoutService: WebLayoutService,
    private followupService: FollowupsService,
    @Inject(ADD_TO_HOMESCREEN) private addToHomeScreen: BehaviorSubject<any>,
  ) {
    super();
    this.username = this.appQuery.$username;
  }

  ngOnInit() {
    const routePars$ = this.route.paramMap.pipe(
      this.notDisposed(),
      map((r) => ({ tenant: r.get("tenant"), child: r.get("id") }))
    );

    combineLatest([this.appQuery.$companies, routePars$])
    .pipe(
      this.notDisposed(),
      filter(([c]) => !!c.length),
    )
    .subscribe(
      ([userCompanies, params]) => {
        if (userCompanies.length === 1 && !params.tenant) {
          this.router.navigate([userCompanies[0].id], { replaceUrl: true });
        } else {
          this.loading = false;
        }
      }
    );

    concat(
      from([[this.loadingCompany]]),
      this.appQuery.$companies
    )
    .subscribe(companies => {
      this.testCompanies = companies.filter((z) => z.id.includes("-demo"));
      this.companies = companies.filter((z) => !z.id.includes("-demo"));
    });

    this.haveTips = this.tipService.tips.pipe(
      this.notDisposed(),
      tap((tips) => {
        if (tips.some((t) => !t.status)) {
          setTimeout(() => this.viewTip(), 1);
        }
      }),
      map((tips) => tips.some((t) => t.status !== "Hidden"))
    );

    // Convert Menu Data (WebMenu) to RouteInfo
    // monitor menu observable - when there is data, build the Routeinfo
    const routeInfos = this.appQuery.menu$
    .pipe(
      filter(menu => !!menu.length),
      distinctUntilChanged((prev, current) => prev.length === current.length),
      map(menu => {
        return menu.map(item => new RouteInfo(item.description, item.url, item.icon, item.parentId, item.menuId));
      }),
      shareReplay()
    );

    this.mainRoutesMaster$ = concat(
      from([new Array(4).fill(this.loadingItem)]),
      routeInfos
    );

    this.childName = routePars$.pipe(
      mergeMap((pp) =>
        this.mainRoutesMaster$.pipe(
          map((r) =>
            !!pp?.child
              ? r.find(
                  (z) => z.layoutName?.toLowerCase() === pp.child.toLowerCase()
                )
              : null
          )
        )
      )
    );

    this.childRoutes = routePars$.pipe(
      this.notDisposed(),
      mergeMap((pp) =>
        this.mainRoutesMaster$.pipe(
          map((r) =>
            !!pp?.child
              ? r.filter(
                  (z) => z.parentId?.toLowerCase() === pp.child.toLowerCase()
                )
              : null
          )
        )
      )
    );

    this.mainRoutes = this.mainRoutesMaster$.pipe(
      map((routes) => routes.filter((r) => !r.parentId).slice(0, 4))
    );
    this.subRoutes = this.mainRoutesMaster$.pipe(
      map((routes) => routes.filter((r) => !r.parentId).slice(4))
    );

    this.metricRoutes = this.mainRoutesMaster$
    .pipe(
      map(routes => routes.filter(route => route.parentId === "Metric")),
    );

    this.metrics = this.metricRoutes.pipe(
      this.notDisposed(),
      distinctUntilChanged(),
      filter(routes => !!routes.length),
      mergeMap(roles => concat(from([new Array(2).fill(dummyMetric())]), this.userService.getSummaryMetrics())),
      shareReplay(),
    );

    this.productSettingService.$loading.pipe(
      this.notDisposed(),
    ).subscribe(loading => {
      this.loading = loading;
    });

    this.tenant$ = this.appQuery.$tenant2;

    this.followupService.getBadgeCountObservable().subscribe(count => {
      this.followupCount = count;
    });
  }

  doAddToHomescreen() {
    // console.log("A2HS: Show prompt");
    this.addToHomeScreen.value.prompt();
    this.addToHomeScreen.next(null);
  }

  arrowStyle(metric: UserMetricSummaryDto): string {
    return metric.trend !== undefined
      ? metric.trend
        ? "fas fa-arrow-up"
        : "fas fa-arrow-down"
      : "fas ";
  }

  layoutLink(link: any[], layoutId: string): any[] {
    const params = link.slice(-1)[0];
    if (typeof params === "object") {
      params["layoutId"] = layoutId;
      return link;
    } else {
      return [...link, { layoutId }];
    }
  }

  viewTip() {
    this.dialog.open(TipOfTheDayComponent, {
      data: this.tipService.tips.value,
    });
  }

  public logoPath(path: string) {
    return "assets/logos/" + path + ".svg?v=1";
  }

  linkLayouts(route: RouteInfo): WebLayoutDto[] {
    return this.layoutService.getWebLayouts(route.layoutName);
  }
}

import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  ListResultDto,
  newSearchDto,
  SearchDto
} from "@shared/models";
import { Observable } from "rxjs";
import { CustomHttpParamEncoder } from "..";
import { Disposable } from "../disposable";
import { DataService, Progress, ServiceConfig } from "./data.service";

export type PromiseFactory = () => Promise<any>;
export type PromiseFactories = PromiseFactory[];

/**
 * Service for accessing List Data (LookupObjects)
 */
@Injectable({
  providedIn: "root",
})
export class ListDataService extends Disposable {

  constructor(protected config: ServiceConfig, private dataService: DataService) {
    super();
  }

  get http() {
    return this.config.http;
  }

  get platformId() {
    return this.config.platformId;
  }

  get storage() {
    return this.config.storage;
  }

  get serverState() {
    return this.config.serverState;
  }

  get appStore() {
    return this.config.appStore;
  }

  get appQuery() {
    return this.config.appQuery;
  }

  uploading(progress: Progress) {
    return this.dataService.uploading(progress);
  }

  safeEncode(value: string | string[]) {
    return this.dataService.safeEncode(value);
  }

  getList<T>(listId: string, limit: number = null): Observable<T[]> {
    const params = {
      ...(limit !== null ? { limit: limit.toString() } : {})// params must always be string typed
    };
    return this.http.get<T[]>(`values/list/${listId}`, { params });
  }

  searchQueryToParams(query: SearchDto): HttpParams {
    return this.dataService.searchQueryToParams(query);
  }

  objectToParams(obj: any): HttpParams {
    return this.dataService.objectToParams(obj);
  }

  executeSequentially(promiseFactories: PromiseFactories = []) {
    return this.dataService.executeSequentially(promiseFactories);
  }

  /**
   * Submits a Query to a List
   * @param listname name of 'list'
   * @param path additional parameters to be coverted to a route/path
   * @param query the query param to append
   */
  queryList<T>(listname: string, path: string | string[], query: string): Observable<T[]> {
    let urlParams = "";
    if(path){
      if(Array.isArray(path)){
        urlParams = path.length ? "/" + path.map((c) => (c ? this.safeEncode(c) : "_")).join("/") : '';
      } else {
        // String version of 'path'
        urlParams = '/'+path;
      }
    }
    query = query || "";
    const mParams = new HttpParams({
      encoder: new CustomHttpParamEncoder(),
    }).append("query", query || "");
    return this.http.get<T[]>(
      `values/list/${listname}${urlParams}` /*machines/models*/,
      { params: mParams }
    );
  }

  /**
   * Useful for Listing, filtering and Paging Lookup lists (ie: Admin Screens)
   * @param lookup shortname of the Lookuplist
   * @param query SearchDto object
   * @returns observable ListResult
   */
   searchList<T>(
    lookup: string,
    query: SearchDto = newSearchDto()
  ): Observable<ListResultDto<T>> {
    const url = `values/search/${lookup}`;
    return this.http.get<ListResultDto<T>>(url, { params: this.searchQueryToParams(query) });
  }

  getListItem<T>(listId: string, modelId: string): Observable<T> {
    return this.http.get<T>(`values/item/${listId}/${modelId}`);
  }

  addListItem<T>(listId: string, model: T): Observable<T> {
    return this.http.post<T>(`values/list/${listId}`, model);
  }

  updateListItem<T>(
    listId: string,
    itemId: string,
    item: T
  ): Observable<T> {
    return this.http.put<T>(
      `values/item/${listId}/${this.safeEncode(itemId)}`,
      item
    );
  }
}

import { Injectable } from "@angular/core";
import { ProductSettingService } from "@modules/common/services";
import { AddressDto, PostalCodeDto } from "@shared/models";
import { combineLatest, Observable } from "rxjs";
import { distinctUntilChanged, map } from "rxjs/operators";
import { DataService, ServiceConfig } from "../../";

@Injectable({
  providedIn: "root"
})
export class AddressService extends DataService {
  gpsTypeMap: { [gpsType: string]: 'poor' | 'partial' };

  constructor(protected config: ServiceConfig, private productSettings: ProductSettingService) {
    super(config);

    combineLatest([this.appQuery.$tenant2.pipe(distinctUntilChanged())])
    .pipe(
      this.notDisposed(),
    )
    .subscribe(() => {
      this.generateGpsTypeMap();
    });
  }

  generateGpsTypeMap(): void {
    const poorGpsTypes = this.productSettings.stringValue("LocationQuality_Poor").split(',').map(i => i.trim());
    const partialGpsTypes = this.productSettings.stringValue("LocationQuality_Partial").split(',').map(i => i.trim());
    this.gpsTypeMap = poorGpsTypes.reduce((pv, cv) => ({...pv, [cv]: 'poor'}), {});
    this.gpsTypeMap = partialGpsTypes.reduce((pv, cv) => ({...pv, [cv]: 'partial'}), this.gpsTypeMap);
  }

  getGpsQuality(address: AddressDto): 'poor' | 'partial' | 'good' {
    return address && this.gpsTypeMap[address.gpsType] || "good";
  }

  queryProvinces(country?: string) {
    let url = "values/list/province";
    if (country) {
      url = url + `/${country}`;
    }
    return this.http.get<PostalCodeDto[]>(url);
  }

  queryCities(country?: string, province?: string) {
    let url = "values/list/city";
    if (country) {
      url = url + `/${country}`;
      if (province) {
        url = url + `/${province}`;
      }
    }
    return this.http.get<PostalCodeDto[]>(url);
  }

  querySuburbs(country?: string, province?: string, city?: string) {
    let url = "values/list/suburb";
    if (country) {
      url = url + `/${country}`;
      if (province) {
        url = url + `/${province}`;
        if (city) {
          url = url + `/${city}`;
        }
      }
    }
    return this.http.get<PostalCodeDto[]>(url);
  }

  queryPostalCodes(query?: string, country?: string, province?: string, city?: string, limit?: number): Observable<PostalCodeDto[]> {
    let url = "values/postalcodes";
    if (country) {
      url = url + `/${country}`;
      if (province) {
        url = url + `/${province}`;
        if (city) {
          url = url + `/${city}`;
        }
      }
    }
    if (query){
      url = url + "?query=" + query;// TODO: smartly amend the limit param
    }
    return this.http.get<PostalCodeDto[]>(url);
  }

  geocode(address: AddressDto): Observable<AddressDto> {
    return this.http.post<AddressDto>("customers/geocode", address).pipe(
      map(addr => {
        address.gpsCoordinates = addr.gpsCoordinates;
        address.gpsQuality = addr.gpsQuality;
        address.gpsType = addr.gpsType;
        return address;
      }));
  }
}

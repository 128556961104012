export * from "./injectables/layout-aware";
export * from "./disposable";
export * from "./services/data.service";
export * from "./utilities/date.utilities";
export * from "./animations";
export * from "./publish-subscribe";
export * from "./pipes/no-zero.pipe";
export * from "./pipes/customer-lookup.pipe";
export * from "./pipes/contact.pipe";
export * from "./pipes/customer.pipe";
export * from "./pipes/sort.pipe";
export * from "./utilities/http.utilities";
export * from "./utilities/array.utilities";
export * from "./utilities/object.utilities";
export * from "./utilities/string.utilities";
export * from "./queue";

export function canAccessCamera(): boolean {
  return !!navigator.mediaDevices; // && !!navigator.mediaDevices.enumerateDevices;
}

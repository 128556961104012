export interface WebLayoutDto {
  /**
   * Autogenerated GUID
   */
  layoutId: string;
  /**
   * 'USER' or 'PUBLIC'
   */
  typeId: WebLayoutType;
  /**
   * Just the short name
   */
  caption: string;
  /**
   * a longer description to describe how this layout is used
   */
  description: string;
  /**
   * '0'/'1' - can be displayed in the main dropdown - or in the sub-dropdown
   */
  quickLink: string;
  /**
   * Icon name (fontawesome)
   */
  icon: string;
  /**
   * actual layout data used for table/column configurations
   */
  data: any;
}

export enum WebLayoutType {
  USER = 'USER',
  PUBLIC = 'PUBLIC',
}

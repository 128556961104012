import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import {
  ApplicationRef,
  Component,
  ComponentRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSidenav } from "@angular/material/sidenav";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Title } from "@angular/platform-browser";
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from "@angular/router";
import { SwUpdate } from "@angular/service-worker";
import { ConnectionService, ConnectionServiceOptions, ConnectionState } from "@modules/connection-service/connection-service.service";
import { SidebarService } from "@modules/sidebar/sidebar.service";
import { combineLatest, concat, interval, Observable, Subscription } from "rxjs";
import { debounceTime, filter, first, map, mergeMap, take, tap } from "rxjs/operators";
import { IListenerHandle } from "@modules/common/services/libraries/listener.library";
import { AttachmentGalleryComponent, AttachmentGalleryData, JsonViewComponent } from "@modules/common/components";
import { NotificationService } from "@core/services";
import { ServerEnvironment } from "../util";
import { Disposable, PublishSubscribe } from "./modules/common";
import { AppQuery, AppStore, LoginStatus } from "./modules/common/app.store";
import {
  AppService, KeyboardShortcutService, KeyboardShortcuts, ProductSettingService, SnackBarService, UserService
} from "./modules/common/services";

// eslint-disable-next-line no-var
declare var standalone: boolean;// Removed Header/Footer parts - Used for Iframe implementations
const sideNavModes: ("over" | "side")[] = ["over", "side"];
export type NetworkStatus = "ONLINE" | "OFFLINE";
@Component({
  selector: "abi-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent extends Disposable implements OnInit, OnDestroy {
  @ViewChild("sidenav", { static: true }) sidenav: MatSidenav;
  @ViewChild("followups", { read: ViewContainerRef })
  followUps: ViewContainerRef;
  showFollowUps = false;
  loading = true;
  username: Observable<string>;
  isMobile$: Observable<boolean>;
  isMobile = false;
  container = "container-fluid";
  defaultWide = true;
  sideNavMode: "over" | "side" = "over";
  sideNavOpen = false;
  menuMode: "push" | "over" | "side" = "side"; // toggle 'open' changes mode to 'over'
  menuOpen = true; // desktop: default open - 'width' controlled by toggle
  private appUpdated = false;
  standalone = true;
  expanded = false;
  showActions = false;
  isPublic = true;
  env: string;
  followupsComponentRef: ComponentRef<any>;
  hasMenu = false;
  useSidebarMenu = false;
  tenant: string = "";
  status!: NetworkStatus;
  currentState!: ConnectionState;
  subscription = new Subscription();
  listener: IListenerHandle;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private worker: SwUpdate,
    private titleService: Title,
    private snackBar: MatSnackBar,
    private snackBarWrapper: SnackBarService,
    public appQuery: AppQuery,
    private responsive: BreakpointObserver,
    private appStore: AppStore,
    private userService: UserService,
    appRef: ApplicationRef,
    private pubsub: PublishSubscribe,
    private mainMenuService: SidebarService,
    dialog: MatDialog,
    private productSettings: ProductSettingService,
    private connectionService: ConnectionService,
    kbService: KeyboardShortcutService,
    private appService: AppService,
    private notificationService: NotificationService
  ) {
    super();

    // Global Keyboad Overrides
    kbService.preventBrowserShortcuts();
    this.listener = kbService.listen({ ...KeyboardShortcuts.HOME, handler: () => this.router.navigate([this.tenant]) });

    this.standalone = standalone;

    pubsub.subscribe("closeSidebar").subscribe(() => {
      if (this.sideNavMode !== "side") {
        this.sidenav?.close();
      }
    });

    pubsub.subscribe("attachment_viewer").subscribe((componentData: AttachmentGalleryData) => {
      const modal = dialog.open(AttachmentGalleryComponent, { data: componentData });
    });

    pubsub.subscribe("resource_modal").subscribe(async (resourceId: string) => {
      const { UserModule } = await import(
        /* webpackChunkName: 'user' */ "./modules/user/user.module"
      );
      const comp = UserModule.components.resourceDetails;
      const modal = dialog.open(comp, { data: resourceId, width: "400px" });
    });

    pubsub
      .subscribe("contract_details")
      .subscribe(async (contractId: string) => {
        const { ContractModule } = await import(
          /* webpackChunkName: 'contract' */ "./modules/contract/contract.module"
        );
        const comp = ContractModule.components.details;
        const modal = dialog.open(comp, { data: contractId, width: "800px" });
      });

    // Admin Functions may include inspecting Raw data
    pubsub
      .subscribe("debug_json")
      .subscribe(async (data: any) => {
        const modal = dialog.open(JsonViewComponent, { data, width: "800px" });
      });

    if (this.worker.isEnabled) {
      const appIsStable$ = appRef.isStable.pipe(
        first((isStable) => isStable === true)
      );
      const check$ = concat(appIsStable$, appQuery.$tenant);
      check$.subscribe((items) => {
        this.worker.checkForUpdate();
        console.log("Check for update");
      });

      // every hour
      const everySixHours$ = interval(1 * 60 * 60 * 1000);
      const everySixHoursOnceAppIsStable$ = concat(
        appIsStable$,
        everySixHours$
      );

      everySixHoursOnceAppIsStable$.subscribe(() => {
        this.worker.checkForUpdate();
        console.log("Check for update scheduled");
      });

      this.worker.activated.subscribe((event) => {
        console.log(
          "[App] Update activated: previous version is",
          event.previous,
          ", current version is",
          event.current
        );
        this.appUpdated = true;
        const sb = this.snackBar.open(
          "Newer version of darkred is available.",
          "Update",
          {
            panelClass: ["snack-white"],
          }
        );
        sb.onAction().subscribe(() => document.location.reload());
      });

      this.worker.available.subscribe((event) => {
        console.log(
          "[App] Update available: current version is",
          event.current,
          "available version is",
          event.available
        );
        this.worker.activateUpdate().then(() => { });
      });
    }

    // this.signalRNotification.initialize();
    this.appQuery.$wideScreen.pipe(this.notDisposed()).subscribe((set) => {
      this.defaultWide = set;
    });

    this.username = this.appQuery.$username;
    this.isMobile$ = this.responsive
      .observe([Breakpoints.Handset, Breakpoints.TabletPortrait])
      .pipe(map((bp) => bp.matches));

    this.isMobile$.subscribe(val => {
      this.isMobile = val;
    });

    combineLatest([
      this.isMobile$,
      this.appQuery.$pinSidenav,
      this.appQuery.$tenant2
    ]).subscribe(([mobile, pinned, tenant]) => {
      if (!mobile && tenant) {
        this.sideNavMode = pinned;
      } else {
        this.sideNavMode = "over";
      }

      // console.log('tenant', tenant)
      this.tenant = tenant;
    });

    // The App is with a valid tenant and logged in
    combineLatest([
      this.isMobile$,
      appQuery.$tenant2,
      appQuery.$loginStatus,
    ])
      .pipe(debounceTime(200))
      .subscribe(([isMobile, tenant, loggedin]) => {
        if (tenant && loggedin === LoginStatus.True) {
          this.getFollowUps();
          // connect to the notification hub
          console.log('connecting to notification service')
          this.notificationService.connectWithToken('token').then(() => {
            console.log('connected to notification service');
          });
        }
      });

    appQuery.$loginStatus
      .subscribe(loginStatus => {
        this.isPublic = loginStatus !== LoginStatus.True;
      });

    appQuery.$tenant2.pipe(filter(t => !t)).subscribe(val => {
      if (this.followupsComponentRef) {
        this.followupsComponentRef.destroy();
      }
    });
    this.appQuery.menu$.subscribe(webMenu => {
      this.hasMenu = !!webMenu.length;
    });
    this.appQuery.useSidebarMenu$.subscribe(useSidebarMenu => {
      this.useSidebarMenu = useSidebarMenu;
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.listener.remove();
  }

  get langLoaded() {
    return this.productSettings.getLanguageLoadedObs() as Observable<boolean>;
  }

  isProduction() {
    return this.env === ServerEnvironment.PRODUCTION;
  }

  ngOnInit() {
    combineLatest([this.isMobile$, this.mainMenuService.sidebarStateObservable$, this.appQuery.$hideRails])
      .pipe(debounceTime(200))
      .subscribe(([isMobile, newState, hideRails]) => {
        if (isMobile || hideRails) {
          this.menuMode = "over";
          this.mainMenuService.disableAutoClose();
          this.menuOpen = ["open", "open-mobile"].includes(newState) || false;
        } else {
          this.menuMode = "side";
          this.mainMenuService.enableAutoClose();
          this.menuOpen = true;
        }
      });

    this.router.events.subscribe(event => {
      // close sidenav on routing
      this.mainMenuService.close();
    });

    this.router.events
      .pipe(
        tap((event) => {
          if (event instanceof NavigationStart) {
            this.loading = true;
          } else if (
            event instanceof NavigationEnd ||
            event instanceof NavigationCancel ||
            event instanceof NavigationError
          ) {
            // If A chunk Failed to load (edge-case: SW didnt update app correctly)
            // Solution: https://github.com/angular/angular/issues/12289#issuecomment-781454207
            if (event instanceof NavigationError && event.error?.name === 'ChunkLoadError') {
              document.location.reload();
            }
            // If SW did update (force reload)
            if (this.appUpdated) {
              // console.log('reloading...');
              document.location.reload();
            } else {
              this.loading = false;
              this.pubsub.publish("closeSidebar");
            }
          }
        }),
        filter((event) => event instanceof NavigationEnd),
        tap((event => {
          this.appService.previousUrl = this.appService.currentUrl;
          this.appService.currentUrl = (event as NavigationEnd).url;
        })),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === "primary"),
        mergeMap((route) => route.data),
        this.notDisposed()
      )
      .subscribe((event) => {
        const newTitle = this.titleGetter(event.title, window.location.hostname);
        this.titleService.setTitle(newTitle);
        this.container =
          event.narrow || !this.defaultWide ? "container" : "container-fluid"; // || !event.wide
      });

    const options: ConnectionServiceOptions = {
      enableHeartbeat: true,
      heartbeatUrl: window.location.origin,
      heartbeatInterval: 60000,
      heartbeatRetryInterval: 2000
    };
    this.connectionService.monitor(options)
      .pipe(this.notDisposed())
      .pipe(
        tap((newState: ConnectionState) => {
          this.currentState = newState;
          if (this.currentState.hasNetworkConnection && this.currentState.hasInternetAccess) {
            // if(this.status === 'OFFLINE')
            //   this.snackBarWrapper.success("Network is back ONLINE.", 5000);
            this.status = 'ONLINE';
          } else {
            if (this.status === 'ONLINE')
              this.snackBarWrapper.warning("Network connection is OFFLINE. Please check your connectivity.", 5000);
            this.status = 'OFFLINE';
          }
        })
      ).subscribe();
  }

  titleGetter(pageTitle: string, hostName: string, appendBrand: string = 'darkred') {
    if (hostName === 'service.kic.co.za') {
      appendBrand = 'KIC';
    }
    if(pageTitle)// hide brand if page title is available
      appendBrand = '';
    return `${pageTitle ? pageTitle : ''}${appendBrand}`;
  }

  /**
   * The Sidenav built-in closer triggers this - we still need to update our custom state ion the Service
   */
  onMenuClosing() {
    this.mainMenuService.close();
  }

  pinMenu(event?: MatSlideToggleChange) {
    this.menuMode = this.menuMode === "over" ? "side" : "over";
  }

  pinSideNav(event?: MatSlideToggleChange) {
    const newMode = (sideNavModes.indexOf(this.sideNavMode) + 1) % 2;
    const pinSidenav = sideNavModes[newMode];

    this.userService
      .updateSetting("PinSidenav", pinSidenav)
      .subscribe((s) => this.appStore.update({ pinSidenav }));
  }

  // example dymanic loading components (for input fields)
  async getFollowUps() {
    if (this.followUps) {
      this.followUps?.clear();
      const { UserModule } = await import(
        /* webpackChunkName: 'user' */ "./modules/user/user.module"
      );
      this.followupsComponentRef = this.followUps.createComponent(UserModule.components.followUps);
    }
  }

  showActionsUpdated(events: MutationRecord[]): void {
    const target = events[0].target;
    if (
      target instanceof HTMLDivElement &&
      target.id === "page-actions-container"
    )
      this.showActions = !!target.childElementCount;
  }

  useAppWrapper() {
    const documentURL = document.URL;
    const tenant = this.appQuery.tenant2;
    if (documentURL.includes(`${tenant}/public`)) {
      // use non-app wrapper (plain for now)
      return false;
    }
    return true;
  }
}

<thead [class]="theadClass">
    <tr>
        <th
            *ngFor="let col of visibleColumns"
            [class]="columnStyle(col)"
            (click)="sortRows(col, $event)"
        >
            <ng-container
                *ngTemplateOutlet="
                    getHeadingTemplate(col)?.templateRef || plainHeading;
                    context: { $implicit: col }
                "
            >
            </ng-container>
        </th>
    </tr>
</thead>
<tbody *ngIf="!onlyColumns">
    <tr *ngIf="errors?.length" class="border-0">
        <td [attr.colspan]="visibleColumns?.length" class="px-0 py-0 border-0">
            <!-- {{ errors | json}} -->
            <div class="alert alert-danger" role="alert">
                <ul class="mb-0">
                    <li *ngFor="let error of errors">{{ error }}</li>
                </ul>
            </div>
        </td>
    </tr>
    <tr *ngIf="!rows" class="border-0">
        <td [attr.colspan]="visibleColumns?.length" class="px-0 py-0 border-0">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </td>
    </tr>
    <tr *ngIf="message" class="border-0">
        <td [attr.colspan]="visibleColumns?.length" class="px-0 py-0 border-0">
            {{ message }}
        </td>
    </tr>
    <ng-container *ngFor="let row of rows; let index = index; trackBy: trackByFunction">
        <tr
            [class]="trClass(row, index)"
            [ngClass]="{'row-clickable': hasRowClickObservers}"
            (click)="rowClicked(index, row, $event)"
        >
            <td *ngFor="let col of visibleColumns" [class]="tdClass(col, row)" (click)="cellClicked(index, row, col, $event)">
                <ng-container
                    *ngTemplateOutlet="
                        getColumnTemplate(col)?.templateRef || plainColumn;
                        context: {
                            row: row,
                            column: col,
                            value: finalValue(row, col),
                            nested: false,
                            index: index,
                            cols: visibleColumns
                        }
                    "
                >
                </ng-container>
            </td>
        </tr>
        <!-- if data existist within the nested row, always display it -->
        <ng-container *ngIf="nestedRows && row[nestedRows]">
            <tr
                [ngClass]="[trClass(row, index), 'nested', hasRowClickObservers ? 'row-clickable' : '']"
                *ngFor="let nRow of row[nestedRows]; index as nIndex"
                (click)="rowClicked(index, row, $event)"
            >
                <td
                    *ngFor="let col of effectiveNestedColumns"
                    [class]="tdClass(col, row)"
                    (click)="cellClicked(index, row, col, $event)"
                >
                    <ng-container
                        *ngTemplateOutlet="
                            getColumnTemplate(col)?.templateRef || plainColumn;
                            context: {
                                row: nRow,
                                column: col,
                                value: finalValue(nRow, col),
                                nested: true,
                                index: nIndex,
                                cols: visibleColumns
                            }
                        "
                    >
                    </ng-container>
                </td>
            </tr>
        </ng-container>
        <!-- alternate nesting method -->
        <!-- <tr @rowAnimation *ngIf="nestedColumnMap && getKey(nestedColumnMap, currentColumnMapLevel) && row[getKey(nestedColumnMap, currentColumnMapLevel)] && displayNesting(row)" [ngClass]="[trClass(row, index), 'nested']"> -->
        <tr *ngIf="nestedColumnMap && getKey(nestedColumnMap, currentColumnMapLevel) && row[getKey(nestedColumnMap, currentColumnMapLevel)] && displayNesting(row)" [ngClass]="[trClass(row, index), 'nested']">
            <td [attr.colspan]="columns.length">
                <table
                    abi-table
                    [canFilter]="true"
                    [columns]="nestedColumnMap.get(getKey(nestedColumnMap, currentColumnMapLevel))"
                    [rows]="row[getKey(nestedColumnMap, currentColumnMapLevel)]"
                    [nestedColumnMap]="nestedColumnMap"
                    [currentColumnMapLevel]="currentColumnMapLevel + 1"
                    [columnMap]="columnMap"
                    [headingMap]="headingMap"
                    [footerMap]="footerMap"
                    [trClass]="trClass"
                    class="table-nested table-fixed-md table-sm"
                    [ngClass]="['level-' + (currentColumnMapLevel + 1)]"
                    (rowClickHandler)="rowClicked($event.index, $event.row, $event.event)"
                    (cellClickHandler)="cellClicked($event.index, $event.row, $event.column, $event.event)"
                    [trackByFunction]="trackByFunction"
                    >
                </table>
            </td>
        </tr>
    </ng-container>
</tbody>
<ng-content *ngIf="onlyColumns && tableInited" selector="tbody"></ng-content>
<tfoot *ngIf="showFooter && rows" [class]="tfootClass">
    <tr>
        <th
            *ngFor="let col of visibleColumns"
            [class]="footerStyle(col)"
            scope="col"
        >
            <ng-container
                *ngTemplateOutlet="
                    getFooterTemplate(col)?.templateRef || plainColumn;
                    context: { $implicit: col }
                "
            >
            </ng-container>
        </th>
    </tr>
</tfoot>

<ng-template #plainColumn let-value="value" let-column="column">
    <span
        *ngIf="!column?.noTruncate; else valueTmpl"
        class="d-block text-truncate w-99"
        >{{ value }}</span
    >
    <ng-template #valueTmpl>
        {{ value }}
    </ng-template>
</ng-template>

<ng-template #plainHeading let-column>
    <span [class.text-nowrap]="!column.wrapHeader" class="d-block text-truncate">
        {{ column.realHeader || header(column, responsive | async) }}
    </span>
    <input
        *ngIf="canFilter && !column.skipFilter"
        type="text"
        [ngModel]="column.filter"
        (click)="stopClick($event)"
        (keyup)="filter(column, $event.target.value)"
    />
</ng-template>

import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { ControlValueAccessor, NgControl } from "@angular/forms";
import { convertToDate } from "@modules/common";
import { BaseCalendarDto } from "@modules/models";
import { NgbCalendar, NgbDate, NgbDateStruct, NgbInputDatepicker } from "@ng-bootstrap/ng-bootstrap";

type HandleDateChange = (days: number) => void;

@Component({
  selector: "abi-dateselector",
  templateUrl: "ability-dateselector.component.html",
  // providers: [{
  //   provide: NG_VALUE_ACCESSOR,
  //   useExisting: forwardRef(() => AbilityDateSelectorComponent),
  //   multi: true
  // }]
})
export class AbilityDateSelectorComponent implements ControlValueAccessor {
  isDisabled = false;
  private _value: Date = Date.today();
  @Input() convertOnChange = true;
  @Input() maxDate: NgbDateStruct = null;
  @Input() minDate: NgbDateStruct = null;
  @Input() nextDays: number = 1;
  @Input() prevDays: number = 1;
  @Input() extraClass = "";
  @Input() disableInput = true;
  @Input() calendar: BaseCalendarDto = null;
  @Input() handleChange: HandleDateChange;
  @Output() changeDate = new EventEmitter<Date>();
  @ViewChild( NgbInputDatepicker) datePicker: NgbInputDatepicker;
  isDayDisabled: (date: NgbDate, current: { month: number }) => boolean; // directly used by the calendar component

  get offsetWidth(): number {
    return this.element.nativeElement.offsetWidth;
  }

  @Input() get value(): any {
    return this._value;
  }

  // TODO: issue here is that the date does not always validate, so check first if it's a good date, before writing it?
  set value(val: any) {
    // dont wanna convert all the time...
    const mVal = this.convertOnChange ? convertToDate(val) : val;
    if (!mVal || !this._value || convertToDate(this._value).valueOf() !== mVal.valueOf()) {
      this._value = mVal;
      this.propagateChange(mVal);
      this.changeDate.emit(mVal?.format() || '');
    }
  }

  private propagateChange = (_: any) => { };
  private propagateTouch = () => { };

  constructor(private element: ElementRef, calendarService: NgbCalendar, private control: NgControl) {
    this.control.valueAccessor = this;
    this.isDayDisabled = (date: NgbDate): boolean => {
      if (this.calendar){
        const currentWeekDay = calendarService.getWeekday(date);
        return !this.calendar.workDays.some(workDay => workDay.dayOfWeek === currentWeekDay);
      }
      return false;
    };
  }

  public get invalid(): boolean {
    return this.control ? this.control.invalid : false;
  }

  public get showError(): boolean {
    if (!this.control) {
     return false;
    }

    const { dirty, touched } = this.control;
    return this.invalid ? (dirty || touched) : false;
  }

  getValidity() {
    return this.showError ? 'is-invalid' : '';
  }

  onBlur() {
    this.propagateTouch();
  }

  writeValue(obj: any): void {
    this._value = convertToDate(obj);
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  prevDate() {
    const val = convertToDate(this.value || Date.today());
    this.value = val.subtract(this.prevDays, "day");
    return true;
  }

  canGoPrev() {
    return this.minDate ? convertToDate(this.minDate).add(this.prevDays, "day") <= convertToDate(this.value || Date.today()) : true;
  }

  canGoNext() {
    return this.maxDate ? convertToDate(this.maxDate).subtract(this.nextDays, "day") > convertToDate(this.value || Date.today())  : true;
  }

  nextDate() {
    const val = convertToDate(this.value || Date.today());
    this.value = val.add(this.nextDays, "day");
    return true;
  }

  valueStr() {
    const val = this.value;
    return val ? (val as Date).format() : "";
  }

  /**
   * REF: https://github.com/ng-bootstrap/ng-bootstrap/blob/11.0.1/src/datepicker/datepicker-day-view.ts
   * @returns   host: {
    'class': 'btn-light',
    '[class.bg-primary]': 'selected',
    '[class.text-white]': 'selected',
    '[class.text-muted]': 'isMuted()',
    '[class.outside]': 'isMuted()',
    '[class.active]': 'focused'
  },
  template: `{{ i18n.getDayNumerals(date) }}`
   */
  isMuted(date, selected, currentMonth, disabled) {
    return !selected && (date.month !== currentMonth || disabled);
  }

  //
}

import { ChangeDetectionStrategy, Component, Input, TemplateRef } from "@angular/core";
import { NgbActiveModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";

export type ButtonType = {
  label: string | (() => string);
  class: string;
  disabled?: () => boolean;
  action: (modal: NgbModalRef) => void;
};

@Component({
  selector: 'abi-custom-dialog',
  templateUrl: './custom-dialog.component.html',
  styleUrls: ['./custom-dialog.component.scss'], // Updated path to CSS file
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomDialogComponent {
  @Input() title: string;
  @Input() body: TemplateRef<any>;
  @Input() buttons: ButtonType[];
  @Input() showSpinner: boolean = false;

  constructor(public activeModal: NgbActiveModal) {}

  getLabel(button: ButtonType): string {
    // return typeof label === 'function' ? label() : label;
    return typeof button.label === 'function' ? button.label() : button.label;
  }
}
